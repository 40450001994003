import React, { useEffect, useState } from 'react';
import { MdNotificationsNone, MdNotificationsActive } from "react-icons/md";
import { getUserNotificationAction } from '../../redux/action/notifications/getUserNotifications'
import { checkUserNotificationExist } from '../../redux/action/notifications/checkUserNotificationExist'
import { useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap';
import { IoMdClose } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import s from "./UserNotification.module.css"
import { oneReadNotification } from '../../redux/action/oneReadNotification';
import { allReadNotification } from '../../redux/action/allReadNotification';
import { MdDone } from "react-icons/md";
import { MdClose } from "react-icons/md";



function UserNotification() {
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);
  const [allNotifications, setAllNotofocations] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [idNotification, setIdNotification] = useState('')
  const [notificationExist, setNotificationExist] = useState(false);

  // ОТКРЫТИЕ ПОДТВЕРЖДЕНИЯ
  const openConfirmOneNotification = (id) => {
    setOpenConfirm(true)
    setIdNotification(id)
  }



  // удаления одного сообщения
  const deleteOneNotification = (idNotification) => {
    oneReadNotification(dispatch, idNotification)
      .then(response => {
        setAllNotofocations(response);
        setNotificationExist(!!response.length);
        setOpenConfirm(false);
      })
  }

  // удаления всех
  const deleteAllNotification = () => {
    allReadNotification(dispatch)
      .then(response => {
        setAllNotofocations(response);
        setNotificationExist(false);
      })
  }

  const getUserNotifications = () => {
    getUserNotificationAction(dispatch)
      .then(response => {
        setAllNotofocations(response)
        setModalShow(true)

      });
  };

  const checkNotificationExist = () => {
    checkUserNotificationExist(dispatch)
      .then(response => {
        setNotificationExist(response);
      });
  };

  useEffect(() => {
    checkNotificationExist();
    const interval = setInterval(checkNotificationExist, 1000 * 60);
    // Очистка интервала при размонтировании компонента
    return () => {
      clearInterval(interval);
    };
  }, []);


  console.log(allNotifications)
  return (
    <div>
      {!notificationExist && <MdNotificationsNone
        size={30}
        title={"Немає нових повідомлення"}
        onClick={getUserNotifications}
        className={s.kolokol}
      />}
      {notificationExist && <MdNotificationsActive
        size={30}
        title={"Ви маєте нові повідомлення"}
        color={"orange"}
        onClick={getUserNotifications}
        className={s.kolokol}
      />}


      {/* Modal */}

      <Modal className={s.modal} show={modalShow} keyboard={true} centered>
        <Modal.Header className={s.modal_header} >

          <Modal.Title className={s.modal_title} >
            Ващі повідомлення
          </Modal.Title>
          <IoMdClose size={23} className={s.modal_close} onClick={() => setModalShow(false)} />
        </Modal.Header>


        <Modal.Body className={s.modal_body}>
          {allNotifications &&
            allNotifications.map((el, index) => (
              <div key={index} className={s.notification_wrapper}>
                <div className={s.notification_header}>
                  <div className={s.date}>
                    {el.created_at}
                  </div>
                  <div>
                    {/* <MdDeleteForever onClick={() => deleteOneNotification(el.id)} size={18} className={s.modal_close} /> */}
                    <MdDeleteForever onClick={() => openConfirmOneNotification(el.id)} size={18} className={s.modal_close} />
                  </div>
                </div>
                <div className={s.notification_body}>
                  {el.message}
                </div>
                {
                  openConfirm && el.id === idNotification ?
                    <div className={s.confirm_box}>
                      <p>
                        Видалити назавжди?
                      </p>
                      <MdDone onClick={() => deleteOneNotification(el.id)} className={s.done} />
                      <MdClose onClick={() => setOpenConfirm(false)} className={s.close} />
                    </div> :
                    <></>
                }

              </div>
            ))}

        </Modal.Body>



        <Modal.Footer className={s.modal_footer}>
          {
            allNotifications.length !== 0 ?
              <span onClick={() => deleteAllNotification()}>
                Видалити усі повідомлення
              </span> :
              <span >
                У Вас не має нових повідомлень
              </span>
          }


        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UserNotification;
