// https://www.i18next.com/overview/comparison-to-others
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Здесь вы можете импортировать и добавить файлы с переводами для различных языков.
// Например, для английского и русского языков:
import translation from '../locales/ua/translationComponents.json';
import adminPanel from '../locales/ua/translationAdmin.json';
import pages from '../locales/ua/translationPages.json';

// обьединяем файлы
const TARNSLATION_UA = {
  translation: {
    ...translation,
    ...adminPanel,
    ...pages,
  },
};

// Определяем настройки i18next
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Загружаемые переводы
    resources: {
      ua: TARNSLATION_UA,
    },
    // Используемый язык по умолчанию, если не найдено подходящего
    fallbackLng: 'ua',
    // Разрешаем использование HTML тегов в переводах
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
