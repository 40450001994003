const GET_INFO_PARTICIPANT_TEAM = 'GET_INFO_PARTICIPANT_TEAM';


// State
const participantTeamState = {
   payload: {
    
   }
};

// reducer принимает state и action
export const infoParticipantTeamReducer = (state = participantTeamState, action) => {
   switch (action.type) {
      case GET_INFO_PARTICIPANT_TEAM:
         // Вывести в консоль action и payload, чтобы отслеживать действия
       
         return { ...state, payload: { ...state.payload, ...action.payload } };
      default:
         return state
   }
};

// Функции с экшенами которые будем передавать в диспатч - action creator
export const infoParticipantTeamActionCreator = (payload) => ({ type: GET_INFO_PARTICIPANT_TEAM, payload});
