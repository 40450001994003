import { createReducer } from '@reduxjs/toolkit';
import { fetchUsers } from './operations';
import getPaginationObject from '../../../../utils/pagination';

// Обработка запроса списка юзеров
const initialState = {
  users: [],
  usersPagination: getPaginationObject(),
};

export const dataUsers = createReducer(initialState, {
  [fetchUsers.fulfilled]: (state, { payload }) => {
    state.users = payload.users_list;
    state.usersPagination = payload.pagination;
  },
});
