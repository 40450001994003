import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from '../../../../services/requestService';

/* ------------------------- получаем список юзеров ------------------------- */
export const fetchUsers = createAsyncThunk('users/fetchUsers', async (pagination) => {
  try {
    const response = await requestService.get('admin-panel/users', { ...pagination });
    return response.data;
  } catch (error) {
    console.log(error);
  }
});
