const GET_OPEN_REDUCER = 'GET_OPEN_REDUCER';

// State
const openEventsState = {
   payload: [] // Инициализируем payload пустым массивом
};

// reducer принимает state и action
export const infoOpenReducer = (state = openEventsState, action) => {
   switch (action.type) {
      case GET_OPEN_REDUCER:
         return { ...state, payload: action.payload }; // Возвращаем пришедший с сервера массив в payload
      default:
         return state;
   }
};

// Функция с экшенами, которую будем передавать в диспатч - action creator
export const infoOpenEventsActionCreator = (payload) => ({ type: GET_OPEN_REDUCER, payload });
