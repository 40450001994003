import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from '../../../../services/requestService';

// выполнение команды
export const applyCommand = createAsyncThunk('settings/applyCommand', async (value) => {
  try {
    const response = await requestService.create(`admin-panel/commands/execute/${value}`);

    return response.data;
  } catch (error) {
    console.log('settingsReducer:', error);

    throw error;
  }
});
// выполнение команды
export const switchTechnicalBreak = createAsyncThunk('settings/switchTechnicalBreak', async (boolian) => {
  try {
    const response = await requestService.update(`admin-panel/technical-break`, {
      checked: boolian,
    });

    return response;
  } catch (error) {
    console.log('settingsReducer:', error);

    throw error;
  }
});

export const clearStatus = createAsyncThunk('settings/clearStatus', async () => {});
