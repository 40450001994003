import { useState } from 'react';
import { FaUserAlt, FaRegUserCircle } from 'react-icons/fa';
import { BiChevronDown } from 'react-icons/bi';
import { BsFillAwardFill, BsFillCalendar2WeekFill } from 'react-icons/bs';
import { RxExit } from 'react-icons/rx';

// Style
import s from './ProfileMenu.module.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import storage from '../../services/localStorageService';
import { useTranslation } from 'react-i18next';

export default function ProfileMenu() {
  const { t } = useTranslation();

  // const dispatch = useDispatch();

  // STORE
  const payload = useSelector((state) => state.info.payload);

  // Закритие открытие меню
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  // Функция LOGOUT удаления ключа из LoacalStorage и перезагрузка страницы
  const removeAccessTokenFromLocalStorage = () => {
    storage.removeToken();
    window.location.reload();
  };

  // Аватарка
  const avatarUser = payload.avatar ?? '';

  return (
    <div style={{ textAlign: 'center', cursor: 'pointer' }}>
      <div
        className={s.user_box}
        id="fade-button"
        onMouseEnter={handleMenuOpen}
        onMouseLeave={handleMenuClose}
        style={{
          display: 'inline-block',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <>{avatarUser ? <img className={s.avatar} src={avatarUser} alt="" /> : <FaRegUserCircle size={30} />}</>

        <BiChevronDown size={30} />
        {menuOpen && (
          <div
            className={s.fade_menu}
            id="fade-menu"
            style={{
              position: 'absolute',
              top: '100%',
              padding: '0px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
              zIndex: 2,
            }}
            onMouseEnter={handleMenuOpen}
            onMouseLeave={handleMenuClose}
          >
            <Link to="/user">
              <div className={s.menu_stroke}>
                <FaUserAlt size={20} />
                &nbsp;&nbsp;{t('profileMenu.profile')}
              </div>
            </Link>
            <Link to="/user/events">
              <div className={s.menu_stroke}>
                <BsFillCalendar2WeekFill size={20} />
                &nbsp;&nbsp;{t('profileMenu.myEvents')}
              </div>
            </Link>
            <Link to="/achievements">
              <div className={s.menu_stroke}>
                <BsFillAwardFill size={20} />
                &nbsp;&nbsp;{t('profileMenu.achievement')}
              </div>
            </Link>
            <div className={s.menu_stroke} onClick={removeAccessTokenFromLocalStorage}>
              <RxExit size={20} />
              &nbsp;&nbsp;{t('profileMenu.out')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
