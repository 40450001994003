import { createSlice } from '@reduxjs/toolkit';
import { addNews, clearNewsStatus } from './operation';

//Функция создание ивентов
const newsSlice = createSlice({
  name: 'news',
  initialState: {
    status: null,
  },

  extraReducers: {
    [addNews.pending](state) {
      state.status = 'loading';
    },
    [addNews.fulfilled](state) {
      state.status = 'response';
    },
    [addNews.rejected](state) {
      state.status = 'rejected';
    },

    [clearNewsStatus.fulfilled](state) {
      state.status = null;
    },
  },
});

export const newsReducer = newsSlice.reducer;
