import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store/index.js';

// Pages
import App from './App';
import './services/i18n.js';

// Styles
import './styles/index.css';
import GlobalStyles from './styles/GlobalStyles.jsx';
// import ChatMessage from './components/ChatMessage/ChatMessage.jsx'

const Root = () => {
  return (
    <Provider store={store}>
      <GlobalStyles />
      <App />
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);
