import { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { RequireAuthLogin } from './components/hoc/RequireAuthLogin';
import { RequireAuthAdmin } from './components/hoc/RequireAuthAdmin';
import { RequireAuth } from './components/hoc/RequireAuth';

import { AddImgAchievementForm } from './components/AdminPanelComponents/forms/AddImgAchievementForm/AddImgAchievementForm';
import { EventsArchiveList } from './components/AdminPanelComponents/EventsArchiveList/EventsArchiveList';
import { CreateEventForm } from './components/AdminPanelComponents/forms/CreateEventForm/CreateEventForm';
import { EventsWaitingList } from './components/AdminPanelComponents/EventsWaitingList/EventsWaitingList';
import { EventsActiveList } from './components/AdminPanelComponents/EventsActiveList/EventsActiveList';
import { EventsOpenList } from './components/AdminPanelComponents/EventsOpenList/EventsOpenList';
import { AdminSettings } from './components/AdminPanelComponents/AdminSettings/AdminSettings';
import { NewsForm } from './components/AdminPanelComponents/forms/NewsForm/NewsForm';
import { UsersList } from './components/AdminPanelComponents/UsersList/UsersList';

const ProjectDescription = lazy(() => import('./pages/ProjectDescription/ProjectDescription'));
const FinishedUserEvents = lazy(() => import('./pages/FinishedUserEvent/FinishedUserEvents'));
const IntroRegisterPage = lazy(() => import('./pages/IntroRegister/IntroRegisterPage'));
const TechnicalBreak = lazy(() => import('./pages/TechnicalBreak/TechnicalBreak'));
const Error404Page = lazy(() => import('./pages/Error404Page/Error404Page'));
const Error403Page = lazy(() => import('./pages/Error403Page/Error403Page'));
const Achievements = lazy(() => import('./pages/Achievements/Achievements'));
const VotingEvents = lazy(() => import('./pages/VotingEvents/VotingEvents'));
const ActiveIvent = lazy(() => import('./pages/ActiveIvent/ActiveIvent'));
const VotingPage = lazy(() => import('./pages/VotingPage/VotingPage'));
const UserEvents = lazy(() => import('./pages/UserEvents/UserEvents'));
const LoginAdmin = lazy(() => import('./pages/LoginAdmin/LoginAdmin'));
const AdminPage = lazy(() => import('./pages/AdminPage/AdminPage'));
const EventRoom = lazy(() => import('./pages/EventRoom/EventRoom'));
const UserInfo = lazy(() => import('./pages/UserInfo/UserInfo'));
const IntroPage = lazy(() => import('./pages/intro/IntroPage'));
const Profile = lazy(() => import('./pages/Profile/Profile'));
const Archive = lazy(() => import('./pages/Archive/Archive'));
const HomePage = lazy(() => import('./pages/Home/HomePage'));
const Board = lazy(() => import('./pages/Board/Board'));
const News = lazy(() => import('./pages/News/News'));
const Faq = lazy(() => import('./pages/Faq/Faq'));

const AppRouter = () => {
  return (
    <Routes>
      {/* ЛОГИН */}
      <Route
        path="/sign_in"
        element={
          <RequireAuthLogin>
            <IntroPage />
          </RequireAuthLogin>
        }
      />
      <Route
        path="/login-admin"
        element={
          <RequireAuthLogin>
            <LoginAdmin />
          </RequireAuthLogin>
        }
      />
      {/* ГЛАВНАЯ ПО УМОЛЧАНИЮ ЛОГИН */}
      <Route path="/" element={<Navigate to="/home" />} />
      {/* РЕГИСТРАЦИЯ */}
      <Route
        path="/join"
        element={
          <RequireAuthLogin>
            <IntroRegisterPage />
          </RequireAuthLogin>
        }
      />
      {/* ГЛАВНАЯ ДОСКА */}
      <Route
        path="/events/open"
        element={
          <RequireAuth>
            <Board />
          </RequireAuth>
        }
      />
      {/* ГЛАВНАЯ */}
      <Route
        path="/home"
        element={
          <RequireAuth>
            <HomePage />
          </RequireAuth>
        }
      />
      {/* FAQ */}
      <Route
        path="/faq"
        element={
          <RequireAuth>
            <Faq />
          </RequireAuth>
        }
      />
      {/* NEWS */}
      <Route
        path="/news"
        element={
          <RequireAuth>
            <News />
          </RequireAuth>
        }
      />
      {/* АРХИВНЫЕ ИВЕНТЫ */}
      <Route
        path="/events/archive"
        element={
          <RequireAuth>
            <Archive />
          </RequireAuth>
        }
      />
      {/* ІВЕНТИ ДЛЯ ГОЛОСУВАННЯ */}
      <Route
        path="/events/voting"
        element={
          <RequireAuth>
            <VotingEvents />
          </RequireAuth>
        }
      />
      {/* ИВЕНТЫ КОТОРЫЕ СЕЙЧАС ПРОХОДЯТ */}
      <Route
        path="/events/active"
        element={
          <RequireAuth>
            <ActiveIvent />
          </RequireAuth>
        }
      />
      {/* ПРОФИЛЬ ЮЗЕРА */}
      <Route
        path="/user"
        element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }
      />
      {/* ІВЕНТИ ЮЗЕРА */}
      <Route
        path="/user/events"
        element={
          <RequireAuth>
            <UserEvents />
          </RequireAuth>
        }
      />
      {/* ИНФО ОПРЕДЕЛЕННОГО ЮЗЕРА */}
      <Route
        path="/user/:uuid"
        element={
          <RequireAuth>
            <UserInfo />
          </RequireAuth>
        }
      />
      {/* Досягнення */}
      <Route
        path="/achievements"
        element={
          <RequireAuth>
            <Achievements />
          </RequireAuth>
        }
      />
      {/* АДМИН ПАНЕЛЬ */}
      <Route
        path="/admin/"
        element={
          <RequireAuthAdmin>
            <AdminPage />
          </RequireAuthAdmin>
        }
      >
        {/* ФОРМЫ И СПИСКИ ИВЕНТОВ И ЮЗЕРОВ */}
        <Route index element={<UsersList />} />
        <Route path="add-achievement-form" element={<AddImgAchievementForm />} />
        <Route path="add-news-form" element={<NewsForm />} />
        <Route path="create-event-form" element={<CreateEventForm />} />
        <Route path="events-waiting-list" element={<EventsWaitingList />} />
        <Route path="events-open-list" element={<EventsOpenList />} />
        <Route path="events-active-list" element={<EventsActiveList />} />
        <Route path="events-archive-list" element={<EventsArchiveList />} />
        <Route path="settings" element={<AdminSettings />} />
      </Route>

      {/* КОМНАТА ИВЕНТА - ИНСТРУКЦИЯ */}
      <Route
        path="/event/details/:uuid"
        element={
          <RequireAuth>
            <EventRoom />
          </RequireAuth>
        }
      />

      {/* СТРАНИЦА ГОЛОСОВАНИЯ (СОТЫ)*/}
      <Route
        path="/voting/:eventUuid"
        element={
          <RequireAuth>
            <VotingPage />
          </RequireAuth>
        }
      />
      {/* АРХИВ ИВЕНТОВ ЮЗЕРА */}
      <Route
        path="/event/:uuid/info"
        element={
          <RequireAuth>
            <FinishedUserEvents />
          </RequireAuth>
        }
      />
      {/* СТРАНИЦА ОПИСАНИЯ ПРОЄКТА*/}
      <Route
        path="/project_description/:eventUuid/:teamUuid"
        element={
          <RequireAuth>
            <ProjectDescription />
          </RequireAuth>
        }
      />

      <Route path="*" element={<Error404Page />} />
      <Route path="/error-403" element={<Error403Page />} />
      <Route path="/technical-break" element={<TechnicalBreak />} />
    </Routes>
  );
};

export default AppRouter;
