import React from 'react';
import { Pagination } from '@mui/material';
import './Pagination.module.css';

const PaginationPanel = (props) => {
  return (
    <Pagination
      count={props.pagination.total_pages ?? 1}
      page={props.pagination.page ?? 1}
      variant="outlined"
      shape="rounded"
      onChange={props.fetchData}
      showFirstButton
      showLastButton
    />
  );
};

export default PaginationPanel;
