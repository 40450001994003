import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import s from './AdminSettings.module.css';
import {
  applyCommand,
  clearStatus,
  switchTechnicalBreak,
} from '../../../redux/reducers/adminPanelReducers/settingsReducer/operation';
import { RedSwitch } from './RedSwitch';

export const AdminSettings = () => {
  const dispatch = useDispatch();
  const status = useSelector(({ settings }) => settings.status);
  const checked = useSelector(({ settings }) => settings.switch);

  const handleChange = (event) => {
    const isChecked = event.target.checked;

    dispatch(switchTechnicalBreak(isChecked));
  };

  const onSubmitBtn = (values) => {
    dispatch(applyCommand(values));
  };

  useEffect(() => {
    status === 'response' && toast('Операція успишно виконана', { type: 'success' });
    status === 'rejected' && toast('Щось пішло не так', { type: 'error' });
  }, [status]);

  useEffect(() => {
    return () => {
      dispatch(clearStatus());
    };
  }, [dispatch]);

  return (
    <>
      <h2 className={s.settingsTitle}>Налаштування</h2>
      <Stack className={s.btnBox} sx={{ mt: 1 }} direction="column" spacing={2}>
        <Button
          title="Закрывает ивент и распределяет награды"
          variant="contained"
          onClick={() => onSubmitBtn('close-events')}
        >
          Закрити івент розподілити нагороду
        </Button>
        <Button
          title="Распределяет участников по командам, которые на момент начала ивента не находяться в командах"
          variant="contained"
          onClick={() => onSubmitBtn('create-teams')}
        >
          Розподілити учасників по командах
        </Button>

        <p className={s.text}>Тех роботи</p>
        <Stack direction="row" spacing={3} justifyContent="center">
          <p className={s.text}>Off</p>
          <RedSwitch onChange={handleChange} checked={checked} />
          <p className={s.text}>On</p>
        </Stack>
      </Stack>
    </>
  );
};
