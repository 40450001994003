import { applyMiddleware, combineReducers, createStore } from 'redux';
import { registerReducer } from '../reducers/registerReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { errorReducer } from '../reducers/errorReducer';
import { infoUserReducer } from '../reducers/infoUserReducer';
import { authReducer } from '../reducers/authReducer';
import {
  dataStatistiks,
  dataRegisteredUsers,
} from '../reducers/adminPanelReducers/statistiksReducer/statistiksReducer';
import { dataUsers } from '../reducers/adminPanelReducers/usersReducer/usersReducer';
import { eventsReducer } from '../reducers/adminPanelReducers/eventReducer/eventSlice';
import { infoOpenReducer } from '../reducers/openEventsReducer';
import { infoActiveReducer } from '../reducers/activeEventsReducer';
import { infoArchiveReducer } from '../reducers/archiveEventsReducer';
import { infoEventReducer } from '../reducers/infoEventReducer';
import { loaderReducer } from '../reducers/loaderReducer';
import { infoParticipantTeamReducer } from '../reducers/ParticipantTeamReducer';
import { devTeamReducer } from '../reducers/DevTeamReducer';
import { achievementReducer } from '../reducers/adminPanelReducers/achievementReducer/achievementSlice';
import { newsReducer } from '../reducers/adminPanelReducers/newsReducer/newsSlice';
import { settingsReducer } from '../reducers/adminPanelReducers/settingsReducer/settingsReducer';

// Обьеденение всех редусеров проекта
const rootReducer = combineReducers({
  register: registerReducer,
  error: errorReducer,
  info: infoUserReducer,
  auth: authReducer,
  dataStatistiks,
  dataRegisteredUsers,
  dataUsers,
  pageLoader: loaderReducer,
  events: eventsReducer,
  achievement: achievementReducer,
  news: newsReducer,
  settings: settingsReducer,
  openEvents: infoOpenReducer,
  activeEvents: infoActiveReducer,
  archiveEvents: infoArchiveReducer,
  infoEvent: infoEventReducer,
  participantTeam: infoParticipantTeamReducer,
  teamInfo: devTeamReducer,
});

// store
export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
