import { Navigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Loader from '../Loader/Loader';
import HeaderIntro from '../headerIntro/headerIntro';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/action/loaderAction';

const RequireAuthLogin = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [auth, setAuth] = useState(undefined);
  const isLoading = useSelector((state) => state.pageLoader.isLoading);

  async function checkAuthToken() {
    const authToken = localStorage.getItem('auth.access_token');

    return authToken !== null;
  }

  useEffect(() => {
    checkAuthToken().then((isAuthenticated) => {
      setAuth(isAuthenticated);
      dispatch(setLoading(false));
    });
  }, [dispatch]);

  if (isLoading || auth === undefined) {
    return <Loader />;
  }

  return (
    <>
      {auth ? (
        // Если пользователь авторизован, перенаправляем на страницу /home
        <Navigate to="/home" state={{ from: location }} />
      ) : (
        // Если пользователь не авторизован, возвращаем исходные элементы (children)
        <>
          <HeaderIntro />
          {children}
        </>
      )}
    </>
  );
};

export { RequireAuthLogin };
