const SET_AUTH_USER = 'SET_AUTH_USER';

// State
const authState = {
    payload: {
        currentUser: undefined,
        isLoggedIn: false,
        token: undefined,
    }
};

// reducer принимает state и action
export const authReducer = (state = authState, action) => {
    switch (action.type) {
        case SET_AUTH_USER:
            return {...state, payload: { ...state.payload, ...action.payload }};
        default:
            return state;
    }
};

// Функции с экшенами которые будем передавать в диспатч - action creator
export const authReducerActionCreator = (payload) => ({ type: SET_AUTH_USER, payload});