const getPaginationObject = () => {
  return {
    page: 1,
    total_pages: 1,
    per_page: 5,
    from: 0,
    to: 0,
    total: 0,
  };
};

export default getPaginationObject;