const REGISTER_USER = 'REGISTER_USER';


// State
const registerState = {
   payload: {
    
   }
}

// reducer принимает state и action
export const registerReducer = (state = registerState, action) => {
   switch (action.type) {
      case REGISTER_USER:
         return { ...state, payload: { ...state.payload, ...action.payload } }
      default:
         return state
   }
}

// Функции с экшенами которые будем передавать в диспатч - action creator
export const addRegisterActionCreator = (payload) => ({ type: REGISTER_USER, payload});
