import React, { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { RiCloseLine } from 'react-icons/ri';

import s from './MobileMenu.module.css';
import { useTranslation } from 'react-i18next';

function MobileMenu() {
  const [show, setShow] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [menuClosed, setMenuClosed] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setMenuClosed(!show);
  }, [show]);

  const handleClose = () => {
    setShow(false);
    setMenuClosed(true);
  };

  const handleShow = () => {
    setShow(true);
    setMenuClosed(false);
  };

  return (
    <>
      <FiMenu size={22} onClick={handleShow} />

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header className={s.title}>
          <Offcanvas.Title>
            <img src="./img/LogoITIS2.png" alt="" />
          </Offcanvas.Title>
          <RiCloseLine size={25} onClick={handleClose} />
        </Offcanvas.Header>
        <Offcanvas.Body className={s.body}>
          <Link onClick={handleClose} className={s.login} to="/sign_in">
            {t('headerIntro.signIn')}
          </Link>
          <Link onClick={handleClose} className={s.registr} to="/join">
            {t('headerIntro.register')}
          </Link>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default MobileMenu;
