const GET_INFO_EVENT = 'GET_INFO_EVENT';


// State
const eventState = {
   payload: {
    
   }
};

// reducer принимает state и action
export const infoEventReducer = (state = eventState, action) => {
   switch (action.type) {
      case GET_INFO_EVENT:
         // Вывести в консоль action и payload, чтобы отслеживать действия
      
         return { ...state, payload: { ...state.payload, ...action.payload } };
      default:
         return state
   }
};

// Функции с экшенами которые будем передавать в диспатч - action creator
export const infoEventActionCreator = (payload) => ({ type: GET_INFO_EVENT, payload});
