import axios from 'axios';
import storage from './localStorageService';
import ResponseError from "./responseError";

const resourceUrl = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create();
let isTokenRefreshing = false;

axiosInstance.interceptors.request.use(async (config) => {
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    // config.headers['Content-type'] = 'application/json';
    config.headers['Accept'] = 'application/json';

    if (storage.hasToken() && config.url !== `${resourceUrl}/login` && config.url !== `${resourceUrl}/join`) {
        if (storage.isTokenExpired() && !isTokenRefreshing) {
            isTokenRefreshing = true;
            await refreshAccessToken().catch(() => {
                storage.removeToken();
            }).finally(() => isTokenRefreshing = false);
        }
        config.headers.Authorization = 'Bearer ' + storage.getToken();
    }

    return config;
});

axiosInstance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        return (new ResponseError().handle(error));
    });

const get = (url, params = {}, headers = {}) => {
    return axiosInstance.get(`${resourceUrl}/${url}`, {
        params: params,
        headers: headers
    });
};

const create = (url, data, headers = {}) => {
    return axiosInstance.post(`${resourceUrl}/${url}`, data, {
        headers: headers
    });
};

const update = (url, data, headers = {}) => {
    return axiosInstance.put(`${resourceUrl}/${url}`, data, {
        headers: headers
    });
};

const destroy = url => axiosInstance.delete(`${resourceUrl}/${url}`);

const refreshAccessToken = () => {
    return create(`refresh-token`).then(response => {
        const newToken = response.data.token;
        storage.setToken(newToken);
    });
};

const requestService = {
    create,
    get,
    update,
    destroy
};

export default requestService;