import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Col, Row, Button, Modal, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { updateEventById } from '../../../../redux/reducers/adminPanelReducers/eventReducer/operations';
import { DIFFICULTY_LEVEL, SPECIALIZATION_NAME } from '../../eventMocs/eventMocs';
import s from './EventEditingForm.module.css';
import TextEditor from '../TextEditor/TextEditor';

export const EventEditingForm = ({ handleClose, eventId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // достаём из редакса ивент по ид
  const waitingEvents = useSelector(({ events }) => events.waitingEvents);
  const status = useSelector(({ events }) => events.statusUpdateEvent);
  const event = waitingEvents.find((event) => eventId === event.id);
  const [editorContent, setEditorContent] = useState(event?.full_description || '');

  const onSubmitForm = (values) => {
    values.full_description = editorContent;
    // отправляем в редакс
    dispatch(updateEventById(values));
  };

  // закрытие модалки
  useEffect(() => {
    if (status === 'loading') handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const initialValues = {
    ...event,
  };

  return (
    <div className={s.formWrapper}>
      <h2 className={s.formTitle}>{t('eventEditingForm.title')}</h2>
      <Modal.Header className={s.formLabel} closeButton />

      <Formik onSubmit={onSubmitForm} initialValues={initialValues}>
        {({ handleSubmit, handleChange, setFieldValue, values }) => {
          const handelAgenda = (e, fieldName, id) => {
            // копируем масив адженда
            const copiArrayAgenda = [...values.agenda];

            // перебираем масив адженда
            const updatedAgendaItem = copiArrayAgenda.map((agendaItem) => {
              // проверяем  если аджендаИтем.ид совпадает с ид который приол из инпута
              if (id === agendaItem.id) {
                // тогда берём название поля и перезаписываем его
                switch (fieldName) {
                  case 'started_at':
                    return {
                      ...agendaItem,
                      started_at: e.target.value,
                    };
                  case 'finished_at':
                    return {
                      ...agendaItem,
                      finished_at: e.target.value,
                    };
                  default:
                    return agendaItem;
                }
              }
              return agendaItem;
            });

            // эта функция формика она принимает изменения и отображает их
            setFieldValue('agenda', updatedAgendaItem);
          };

          return (
            <Form onSubmit={handleSubmit}>
              {/* ----------------------------- НАЗВАНИЕ ИВЕНТА ----------------------------- */}
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className={s.formLabel}>{t('eventEditingForm.name')}</Form.Label>
                <Form.Control
                  className={s.inputStyle}
                  required
                  type="text"
                  placeholder="Приключение рубика"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  autoFocus
                />
              </Form.Group>

              {/* ----------------------------- ОПИСАНИЕ ИВЕНТА ----------------------------- */}
              <Row className={s.row_description} style={{ borderBottom: 'solid 1px grey', marginBottom: '10px' }}>
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                    <Form.Label className={s.formLabel}>{t('eventEditingForm.descriptionShort')}</Form.Label>
                    <Form.Control
                      className={s.inputStyle}
                      required
                      as="textarea"
                      name="description"
                      placeholder="Техт..."
                      rows={3}
                      value={values.description}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {/* ----------------------------- ОПИСАНИЕ ТЗ ----------------------------- */}
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                    <Form.Label className={s.formLabel}>{t('eventEditingForm.fullDescriptionTZ')}</Form.Label>
                    {/* <Form.Control
                      className={s.inputStyle}
                      required
                      as="textarea"
                      name="full_description"
                      placeholder="Техт..."
                      rows={3}
                      value={values.full_description.content}
                      onChange={handleChange}
                    /> */}
                    <TextEditor
                      initialValue={values.full_description || ''}
                      setEditorContent={setEditorContent}
                      CrEvent="CrEvent"
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* ----------------------------- НАЗНАЧИТЬ КОЛИЧЕСТВО УЧАСТНИКОВ ----------------------------- */}
              <Row
                className={s.row_count}
                style={{ borderBottom: 'solid 1px grey', marginBottom: '10px', paddingBottom: '10px' }}
              >
                <Col>
                  <Form.Group>
                    <Form.Label className={s.formLabel}>{t('eventEditingForm.numberOfUsers')}</Form.Label>
                    <Form.Control
                      className={s.inputStyle}
                      type="number"
                      name="team_max_users_count"
                      value={values.team_max_users_count}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                {/* ----------------------------- НАЗНАЧИТЬ КОЛИЧЕСТВО МОНЕТ ----------------------------- */}
                <Col>
                  <Form.Group>
                    <Form.Label className={s.formLabel}>{t('eventEditingForm.numberOfCoins')}</Form.Label>
                    <Form.Control
                      className={s.inputStyle}
                      type="text"
                      name="coins_achieve"
                      value={values.coins_achieve}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                {/* ----------------------------- НАЗНАЧИТЬ РЕЙТИНГ ----------------------------- */}
                <Col>
                  <Form.Group>
                    <Form.Label className={s.formLabel}>{t('eventEditingForm.ratingEvent')}</Form.Label>
                    <Form.Control
                      className={s.inputStyle}
                      type="text"
                      name="rating_achieve"
                      value={values.rating_achieve}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* ----------------------------- ВЫБОР ПРОФЕССИ ----------------------------- */}
              <Row className={s.row_description} style={{ borderBottom: 'solid 1px grey', marginBottom: '10px' }}>
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                    <Form.Label className={s.formLabel}>{t('eventEditingForm.selectAProfession')}</Form.Label>
                    <Form.Select
                      className={s.inputStyle}
                      name="specialization"
                      value={values.specialization}
                      onChange={handleChange}
                    >
                      <option style={{ fontSize: '1.6rem' }}>{t('eventEditingForm.specializationName')}</option>
                      {SPECIALIZATION_NAME.map((name) => (
                        <option style={{ fontSize: '1.6rem' }} key={name} value={name}>
                          {t(`event.${name}`)}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                {/* ----------------------------- ВЫБОР СЛОЖНОСТИ ----------------------------- */}
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea3">
                    <Form.Label className={s.formLabel}>{t('eventEditingForm.defficultyLevel')}</Form.Label>
                    <RadioGroup
                      className={s.formCheckItem}
                      row
                      aria-label="difficulty_level"
                      name="difficulty_level"
                      value={values.difficulty_level}
                      onChange={(e) => setFieldValue('difficulty_level', e.target.value)}
                    >
                      {DIFFICULTY_LEVEL.map((name) => (
                        <FormControlLabel
                          key={name}
                          value={name}
                          control={<Radio />}
                          label={t(`eventEditingForm.${name}`)}
                        />
                      ))}
                    </RadioGroup>
                  </Form.Group>
                </Col>

                {/*  ------------------- ДОБАВЛЕНИЕ ИЗОБРАЖЕНИЕ ИВЕНТА ------------------ */}
                <Col>
                  <Form.Group className="position-relative mb-3">
                    <Form.Label className={s.formLabel}>{t('eventEditingForm.img')}</Form.Label>

                    {/* кнопка просмотра изображения */}
                    <Form.Label className={s.formLabel}>
                      <Button
                        style={{ fontSize: '1.6rem', marginLeft: '10px' }}
                        type="button"
                        href={event.event_img_path}
                        target="_blank"
                      >
                        {t('eventEditingForm.btnImg')}
                      </Button>
                    </Form.Label>

                    {/* поле загрузки изображения */}
                    <Form.Control
                      className={s.inputStyle}
                      type="file"
                      name="event_img"
                      onChange={(e) => setFieldValue('event_img', e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/*  ------------------- ДАТАПИКЕР ДЛЯ РАСПИСАНИЯ ------------------ */}
              <Row className={s.datapicer}>
                {values.agenda.map(({ id, name, started_at, finished_at }, index) => {
                  return (
                    <Col style={{ width: '480px' }} key={name}>
                      <Form.Label className={s.formLabel}>
                        {`${index + 1}. `}
                        {t(`agenda.${name}`)}
                      </Form.Label>
                      <Form.Group className="mb-4">
                        <Row className={s.row_data}>
                          <Col>
                            <Form.Group>
                              <Form.Label className={s.formLabelPicker}>{t('eventEditingForm.start')}</Form.Label>
                              <Form.Control
                                className={s.inputStyle}
                                type="date"
                                value={started_at}
                                onChange={(e) => handelAgenda(e, 'started_at', id)}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label className={s.formLabelPicker}>{t('eventEditingForm.end')}</Form.Label>
                              <Form.Control
                                className={s.inputStyle}
                                type="date"
                                value={finished_at}
                                onChange={(e) => handelAgenda(e, 'finished_at', id)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  );
                })}
              </Row>
              <Button style={{ fontSize: '1.6rem' }} type="submit">
                {t('eventEditingForm.submitBtn')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
