import { createSlice } from '@reduxjs/toolkit';
import {
  createEvent,
  fetchWaitingEvents,
  fetchOpenEvents,
  fetchActiveEvents,
  fetchArchiveEvents,
  updateEventById,
} from './operations';
import getPaginationObject from '../../../../utils/pagination';

//Функция создание ивентов
const eventSlice = createSlice({
  name: 'events',
  initialState: {
    waitingEvents: [],
    openEvents: [],
    activeEvents: [],
    arhiveEvents: [],
    waitingEventsEventsPagination: getPaginationObject(),
    openEventsPagination: getPaginationObject(),
    activeEventsPagination: getPaginationObject(),
    archiveEventsPagination: getPaginationObject(),
    status: null,
    statusUpdateEvent: null,
  },

  extraReducers: {
    [fetchWaitingEvents.fulfilled](state, { payload }) {
      state.waitingEvents = payload.waiting_events;
      state.waitingEventsEventsPagination = payload.pagination;
      state.status = null;
    },

    [fetchOpenEvents.fulfilled](state, { payload }) {
      state.openEvents = payload.open_events;
      state.openEventsPagination = payload.pagination;
    },
    [fetchActiveEvents.fulfilled](state, { payload }) {
      state.activeEvents = payload.active_events;
      state.activeEventsPagination = payload.pagination;
    },
    [fetchArchiveEvents.fulfilled](state, { payload }) {
      state.arhiveEvents = payload.archive_events;
      state.archiveEventsPagination = payload.pagination;
    },

    [createEvent.pending](state) {
      state.status = 'loading';
    },

    [createEvent.fulfilled](state) {
      state.status = 'response';
    },

    [createEvent.rejected](state) {
      state.status = 'rejected';
    },

    [updateEventById.pending](state) {
      state.statusUpdateEvent = 'loading';
    },

    [updateEventById.fulfilled](state) {
      state.statusUpdateEvent = 'response';
    },

    [updateEventById.rejected](state) {
      state.statusUpdateEvent = 'rejected';
    },
  },
});

export const eventsReducer = eventSlice.reducer;
