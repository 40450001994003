/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import { FaTelegramPlane, FaLinkedin } from 'react-icons/fa';

// Styles
import s from './Footer.module.css';
import dayjs from 'dayjs';

function Footer() {
  const curentYear = dayjs().format('YYYY');

  return (
    <Container className={s.container} fluid>
      <Row className={s.row}>
        <Col className={s.col} xs="12" lg="2" md="2">
          © 2023-{curentYear} ITIS development
        </Col>
        <Col className={s.col} xs="12" lg="2" md="5">
        <a href="./img/docs/ITIS privacy Updated-copy.pdf">
            Privacy policy
        </a>
   
        </Col>
        <Col className={s.col} xs="12" lg="2" md="5">
          Support:{' '}
          <a className={s.support} href="mailto:support@itisdev.com.ua">
          support@itisdev.com.ua
          </a>
        </Col>
        <Col className={s.col} xs="12" lg="2" md="3">
          <a className={s.social_link} href="https://t.me/itis_development_news" target="_blank" rel="noreferrer">
            <FaTelegramPlane size={20} />
          </a>

          <a style={{ marginLeft: '10px' }} className={s.social_link} href="https://www.linkedin.com/company/itis-development/?viewAsMember=true" target="_blank">
            <FaLinkedin size={20} />
          </a>
        </Col>
        <Col className={s.col} xs="12" lg="2" md="2">
          Designed by <span style={{ color: 'var(--secondary-color)' }}>A&O Agency</span>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
