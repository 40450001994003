const GET_ARCHIVE_REDUCER = 'GET_ARCHIVE_REDUCER';

// State
const archiveEventsState = {
   payload: [] // Инициализируем payload пустым массивом
};

// reducer принимает state и action
export const infoArchiveReducer = (state = archiveEventsState, action) => {
   switch (action.type) {
      case GET_ARCHIVE_REDUCER:
         return { ...state, payload: action.payload }; // Возвращаем пришедший с сервера массив в payload
      default:
         return state;
   }
};

// Функция с экшенами, которую будем передавать в диспатч - action creator
export const infoArchiveEventsActionCreator = (payload) => ({ type: GET_ARCHIVE_REDUCER, payload });
