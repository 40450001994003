const GET_ACTIVE_REDUCER = 'GET_ACTIVE_REDUCER';

// State
const activeEventsState = {
   payload: [] // Инициализируем payload пустым массивом
};

// reducer принимает state и action
export const infoActiveReducer = (state = activeEventsState, action) => {
   switch (action.type) {
      case GET_ACTIVE_REDUCER:
         return { ...state, payload: action.payload }; // Возвращаем пришедший с сервера массив в payload
      default:
         return state;
   }
};

// Функция с экшенами, которую будем передавать в диспатч - action creator
export const infoActiveEventsActionCreator = (payload) => ({ type: GET_ACTIVE_REDUCER, payload });
