import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Styles
import s from './HeaderIntro.module.css';
import MobileMenu from '../MobileMenu/MobileMenu';
import { useTranslation } from 'react-i18next';

function HeaderIntro() {
  const { t } = useTranslation();

  return (
    // Комент
    <Container className={s.container} fluid>
      <Row className={s.row}>
        <Col xs="5" lg="4" md="4">
          <Link to="/sign_in">
            <img className={s.logo} src="/img/LogoITIS2.png" alt="" />
          </Link>
        </Col>
        <Col className={s.btn_desc} xs="3" lg="3" md="3">
          <Link className={s.login} to="/sign_in">
            {t('headerIntro.signIn')}
          </Link>
          <Link className={s.registr} to="/join">
            {t('headerIntro.register')}
          </Link>
        </Col>
        <Col className={s.btn_mob} xs="3" lg="3">
          <MobileMenu />
        </Col>
      </Row>
    </Container>
  );
}

export default HeaderIntro;
