import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { createEvent } from '../../../../redux/reducers/adminPanelReducers/eventReducer/operations';
import { DIFFICULTY_LEVEL, SPECIALIZATION_NAME } from '../../eventMocs/eventMocs';
import { Col, Row } from 'react-bootstrap';
import s from './CreateEventForm.module.css';
import { useTranslation } from 'react-i18next';
import { AGENDA_LIST } from './mocks';
import { useNavigate } from 'react-router-dom';
import TextEditor from '../TextEditor/TextEditor';

export const CreateEventForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(); //переводчик i18n
  const status = useSelector(({ events }) => events.status); //статус ивента
  const [agendaItems, setAgendaItems] = useState(AGENDA_LIST); //масив полей ({ name: 'recruitment', started_at: '', finished_at: '' })
  const [editorContent, setEditorContent] = useState('');

  const onSubmitForm = (values) => {
    values.full_description = editorContent;
    dispatch(createEvent(values));
  };

  // переход на страницу открытые после создания ивента
  useEffect(() => {
    if (status === 'response') {
      navigate('/admin/events-waiting-list', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const initialValues = {
    title: 'Ивент 1',
    description: 'Опис івенту',
    full_description: 'Внести повний опис ТЗ',
    specialization: 'frontend',
    team_max_users_count: 2,
    coins_achieve: '5-10',
    rating_achieve: '10-20',
    difficulty_level: 'junior',
    event_img: null,
    agenda: agendaItems,
  };

  return (
    <div className={s.formWrapper}>
      <h2 className={s.formTitle}>{t('createEventForm.titleForm')}</h2>
      <Formik onSubmit={onSubmitForm} initialValues={initialValues}>
        {({ handleSubmit, handleChange, setFieldValue, values }) => {
          const handelAgenda = (e, fieldName, index) => {
            // копирую масив для безопасности
            const updatedAgendaItem = [...agendaItems];

            // отбераю обьект по индексу выбираю название ключа по fieldName и перезаписываю его
            updatedAgendaItem[index][fieldName] = e.target.value;

            // передаю в состояние
            setAgendaItems(updatedAgendaItem);
          };

          return (
            <Form onSubmit={handleSubmit}>
              {/* ----------------------------- НАЗВАНИЕ ИВЕНТА ----------------------------- */}
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className={s.formLabel}>{t('createEventForm.name')}</Form.Label>
                <Form.Control
                  className={s.inputStyle}
                  required
                  type="text"
                  placeholder={t('createEventForm.name')}
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  autoFocus
                />
              </Form.Group>

              {/* ----------------------------- ОПИСАНИЕ ИВЕНТА ----------------------------- */}
              <Row className={s.row_description} style={{ borderBottom: 'solid 1px grey', marginBottom: '10px' }}>
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                    <Form.Label className={s.formLabel}>{t('createEventForm.descriptionShort')}</Form.Label>
                    <Form.Control
                      className={s.inputStyle}
                      required
                      as="textarea"
                      name="description"
                      placeholder="Техт..."
                      rows={3}
                      value={values.description}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {/* -----------------------------ПОЛНОЕ ОПИСАНИЕ ТЗ ----------------------------- */}
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                    <Form.Label className={s.formLabel}>{t('createEventForm.fullDescriptionTZ')}</Form.Label>
                    {/* <Form.Control
                      className={s.inputStyle}
                      required
                      as="textarea"
                      name="full_description"
                      placeholder="Техт..."
                      rows={3}
                      value={values.full_description}
                      onChange={handleChange}
                    /> */}
                    <TextEditor setEditorContent={setEditorContent} CrEvent="CrEvent" />
                  </Form.Group>
                </Col>
              </Row>

              {/* ----------------------------- НАЗНАЧИТЬ КОЛИЧЕСТВО УЧАСТНИКОВ ----------------------------- */}
              <Row
                className={s.row_count}
                style={{ borderBottom: 'solid 1px grey', marginBottom: '10px', paddingBottom: '10px' }}
              >
                <Col>
                  <Form.Group>
                    <Form.Label className={s.formLabel}>{t('createEventForm.numberOfUsers')}</Form.Label>
                    <Form.Control
                      className={s.inputStyle}
                      type="number"
                      name="team_max_users_count"
                      value={values.team_max_users_count}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                {/* ----------------------------- НАЗНАЧИТЬ КОЛИЧЕСТВО МОНЕТ ----------------------------- */}
                <Col>
                  <Form.Group>
                    <Form.Label className={s.formLabel}>{t('createEventForm.numberOfCoins')}</Form.Label>
                    <Form.Control
                      className={s.inputStyle}
                      type="text"
                      name="coins_achieve"
                      value={values.coins_achieve}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                {/* ----------------------------- НАЗНАЧИТЬ РЕЙТИНГ ----------------------------- */}
                <Col>
                  <Form.Group>
                    <Form.Label className={s.formLabel}>{t('createEventForm.ratingEvent')}</Form.Label>
                    <Form.Control
                      className={s.inputStyle}
                      type="text"
                      name="rating_achieve"
                      value={values.rating_achieve}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* ----------------------------- ВЫБОР ПРОФЕССИ ----------------------------- */}
              <Row className={s.row_prof} style={{ borderBottom: 'solid 1px grey', marginBottom: '10px' }}>
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                    <Form.Label className={s.formLabel}>{t('createEventForm.selectAProfession')}</Form.Label>
                    <Form.Select
                      className={s.inputStyle}
                      name="specialization"
                      value={values.specialization}
                      onChange={handleChange}
                    >
                      <option style={{ fontSize: '1.6rem' }}>{t('createEventForm.specializationName')}</option>
                      {SPECIALIZATION_NAME.map((name) => {
                        return (
                          <option style={{ fontSize: '1.6rem' }} key={name} value={name}>
                            {t(`createEventForm.${name}`)}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>

                {/* ----------------------------- ВЫБОР СЛОЖНОСТИ ----------------------------- */}
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea3">
                    <Form.Label className={s.formLabel}>{t('createEventForm.defficultyLevel')}</Form.Label>
                    <RadioGroup
                      className={s.formCheckItem}
                      row
                      aria-label="difficulty_level"
                      name="difficulty_level"
                      value={values.difficulty_level}
                      onChange={(e) => setFieldValue('difficulty_level', e.target.value)}
                    >
                      {DIFFICULTY_LEVEL.map((name) => (
                        <FormControlLabel
                          key={name}
                          value={name}
                          control={<Radio />}
                          label={t(`createEventForm.${name}`)}
                        />
                      ))}
                    </RadioGroup>
                  </Form.Group>
                </Col>

                {/*  ------------------- ДОБАВЛЕНИЕ ИЗОБРАЖЕНИЕ ИВЕНТА ------------------ */}
                <Col>
                  <Form.Group className="position-relative mb-3">
                    <Form.Label className={s.formLabel}>{t('createEventForm.img')}</Form.Label>
                    <Form.Control
                      className={s.inputStyle}
                      type="file"
                      name="event_img"
                      onChange={(e) => setFieldValue('event_img', e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/*  ------------------- ДАТАПИКЕР ДЛЯ РАСПИСАНИЯ ------------------ */}
              <Row className={s.datapicer}>
                {agendaItems.map(({ name, started_at, finished_at }, index) => {
                  return (
                    <Col style={{ width: '480px' }} key={name}>
                      <Form.Label className={s.formLabel}>
                        {`${index + 1}. `}
                        {t(`agenda.${name}`)}
                      </Form.Label>
                      <Form.Group className="mb-4">
                        <Row className={s.row_data}>
                          <Col>
                            <Form.Group>
                              <Form.Label className={s.formLabelPicker}>{t('createEventForm.start')}</Form.Label>
                              <Form.Control
                                className={s.inputStyle}
                                type="date"
                                value={started_at}
                                onChange={(e) => handelAgenda(e, 'started_at', index)}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label className={s.formLabelPicker}>{t('createEventForm.end')}</Form.Label>
                              <Form.Control
                                className={s.inputStyle}
                                type="date"
                                value={finished_at}
                                onChange={(e) => handelAgenda(e, 'finished_at', index)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  );
                })}
              </Row>
              <Button style={{ fontSize: '1.6rem' }} type="submit">
                {status === 'loading' ? 'Loader...' : t('createEventForm.submitBtn')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
