import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './components/Loader/Loader';
import Footer from './components/Footer/Footer';
import AppRouter from './AppRoutes';

/** @link https://fkhadra.github.io/react-toastify/introduction */
function App() {
  return (
    <div className="App">
      <ToastContainer
        style={{ fontSize: '15px' }}
        position="top-center"
        hideProgressBar
        newestOnTop={false}
        // autoClose={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <AppRouter />
        </Suspense>
      </BrowserRouter>

      <Footer />
    </div>
  );
}

export default App;
