import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { fetchActiveEvents } from '../../../redux/reducers/adminPanelReducers/eventReducer/operations';
import s from '../../../pages/AdminPage/AdminPage.module.css';
import PaginationPanel from '../../Pagination/Pagination';
import style from '../Table/Table.module.css';
import Loader from '../../Loader/Loader';
import Table from '../Table/Table';
import { COLS_ACTIVE_LIST } from '../eventMocs/eventMocs';

export const EventsActiveList = () => {
  const dispatch = useDispatch();
  const activeEvents = useSelector(({ events }) => events.activeEvents);
  const pagination = useSelector((state) => state.events.activeEventsPagination);
  const status = useSelector((state) => state.error.payload.status);
  const { t } = useTranslation();

  const handlePageSelect = (_, value) => {
    dispatch(fetchActiveEvents({ ...pagination, page: value }));
  };

  const columns = useMemo(() => COLS_ACTIVE_LIST, []);

  useEffect(() => {
    dispatch(fetchActiveEvents({ ...pagination, per_page: 10 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (status !== 200 && !activeEvents && !pagination) {
    return <Loader />;
  }

  return (
    <>
      <h2 className={s.tableTitle}>{t('eventsActiveList.activeEvents')}</h2>

      <div className={style.tableContainer}>
        <Table data={activeEvents} columns={columns} />
        <div className={s.pagination_box} xs="6" lg="6" md="6">
          <PaginationPanel fetchData={handlePageSelect} pagination={pagination} />
        </div>
      </div>
    </>
  );
};
