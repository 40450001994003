import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from '../../../../services/requestService';

// Добавление ачивку
export const addAchievement = createAsyncThunk('achievement/addAchievement', async (achievementData) => {
  try {
    // formData используется для обработки файла перед загрузкой на сервер
    const formData = new FormData();
    Object.keys(achievementData).forEach((key) => formData.append(key, achievementData[key]));

    const response = await requestService.create('admin-panel/achievement', formData);

    return response.data;
  } catch (error) {
    console.log('помилка при додаванні ачівки:', error);

    throw error;
  }
});

export const clearAchievementStatus = createAsyncThunk('achievement/clearStatus', async () => {});

//  получаем список кодов ачивок
export const fetchAchievementCodes = createAsyncThunk('achievement/fetchAchievementCodes', async (_, thunkAPI) => {
  try {
    const response = await requestService.get('admin-panel/achievements-codes');
    return response.data;
  } catch (error) {
 
    return thunkAPI.rejectWithValue(error.message);
  }
});
