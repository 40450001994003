import Modal from 'react-bootstrap/Modal';

export const ModalW = ({ children, handleClose, show }) => {
  return (
    <>
      <Modal size={'lg'} show={show} onHide={handleClose}>
        {children}
      </Modal>
    </>
  );
};
