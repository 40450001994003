import Modal from 'react-bootstrap/Modal';
import { AiFillLinkedin } from 'react-icons/ai';
import { FaTelegramPlane, FaGithub, FaFacebookSquare } from 'react-icons/fa';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Avatar, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import CircleIcon from '@mui/icons-material/Circle';

import s from './ModalUserInfo.module.css';
import { IconContext } from 'react-icons';
import { BiMessageSquareCheck, BiMessageSquareX } from 'react-icons/bi';
const styleAvatar = { margin: '0 auto', width: '150px', height: '150px', borderRadius: '50%', background: 'grey' };

// const nameBtn = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const ModalUserInfo = ({ user }) => {
  const { t } = useTranslation();
  const {
    name,
    created_at,
    avatar,
    email,
    facebook,
    gender,
    github,
    telegram,
    linkedIn,
    coins,
    rating,
    achievements,
    email_verified_at,
  } = user;

  // const status = false; //заменить на состояние которое приходит с сервера

  // const indicatorSatate = status ? (
  //   <CircleIcon color="success" title="Користувачь онлайн" sx={{ width: 30, height: 30 }} />
  // ) : (
  //   <CircleIcon color="error" title="Користувачь офлайн" sx={{ width: 30, height: 30 }} />
  // );

  const emailVerified = email_verified_at ? (
    <IconContext.Provider value={{ color: 'green', size: '15px' }}>
      <BiMessageSquareCheck title={t('baseInfo.success')} />
    </IconContext.Provider>
  ) : (
    <IconContext.Provider value={{ color: 'red', size: '15px' }}>
      <BiMessageSquareX title={t('baseInfo.unsuccess')} />
    </IconContext.Provider>
  );

  return (
    <>
      <Modal.Header closeButton />
      <Modal.Body>
        {/* ----------------------- Інформація про користувача ----------------------- */}
        <h2 className={s.title}>{t('modalUserInfo.infoUser')}</h2>
        <Row className={s.row}>
          <Col className={s.col} xs="11" lg="10" md="10">
            <Avatar style={styleAvatar} alt={name} src={avatar} />

            {/* индикатор онлайн */}
            {/* <div className={s.indicatorPosition}>{indicatorSatate}</div> */}

            <div className={s.info_box}>
              <div className={s.text_info}>
                <p className={s.title_name}>
                  <span className={s.label}>{t('modalUserInfo.name')}:</span> {name}
                </p>

                <p className={s.title_name}>
                  <span className={s.label}>{t('modalUserInfo.email')}:</span> {email} {emailVerified}
                </p>

                <p className={s.title_name}>
                  <span className={s.label}>{t('modalUserInfo.gender')}:</span> {t(`modalUserInfo.${gender}`)}
                </p>

                <p className={s.title_name}>
                  <span className={s.label}>{t('modalUserInfo.registrationDate')}:</span> {created_at}
                </p>

                <div style={{ marginTop: '5px' }}>
                  <a
                    className={!linkedIn ? s.notLinkStyle : s.social}
                    href={linkedIn}
                    target="_blank"
                    without
                    rel="noreferrer"
                  >
                    <AiFillLinkedin size={35} />
                  </a>
                  <a
                    className={!github ? s.notLinkStyle : s.social}
                    href={github}
                    target="_blank"
                    without
                    rel="noreferrer"
                  >
                    <FaGithub size={35} />
                  </a>
                  <a
                    className={!facebook ? s.notLinkStyle : s.social}
                    href={facebook}
                    target="_blank"
                    without
                    rel="noreferrer"
                  >
                    <FaFacebookSquare size={35} />
                  </a>
                  <a
                    className={!telegram ? s.notLinkStyle : s.social}
                    href={telegram}
                    target="_blank"
                    without
                    rel="noreferrer"
                  >
                    <FaTelegramPlane size={35} />
                  </a>
                </div>
              </div>
            </div>
          </Col>

          {/* ----------------------- Скарги на користувача ----------------------- */}
          <h2 className={s.title}>{t('modalUserInfo.userComplaints')}</h2>
          <Col className={s.col} xs="11" lg="10" md="10">
            <div className={s.text_info}>
              <p className={s.title_name}>вывести количество жалоб иконки и число напротив</p>

              <p className={s.title_name}>
                <span className={s.label}>{t('modalUserInfo.aggressiveness')}:</span> 1
              </p>
              <p className={s.title_name}>
                <span className={s.label}>{t('modalUserInfo.profanity')}:</span> 2
              </p>
              <p className={s.title_name}>
                <span className={s.label}>{t('modalUserInfo.notInvolved')}:</span> 2
              </p>
              <p className={s.title_name}>
                <span className={s.label}>{t('modalUserInfo.other')}:</span> 0
              </p>
            </div>
          </Col>

          {/* ----------------------- Досягнення користувача ----------------------- */}
          <h2 className={s.title}>{t('modalUserInfo.userAchievements')}</h2>
          <Col className={s.col} xs="11" lg="10" md="10">
            <div className={s.raingWrapper}>
              <Stack
                style={{ marginBottom: '10px' }}
                spacing={{ xs: 1, sm: 2 }}
                justifyContent="center"
                direction="row"
                useFlexGap
                flexWrap="wrap"
              >
                <div className={s.coin_wrapper}>
                  <img src="/img/coin.png" alt="" />
                  &nbsp;
                  {coins} <span className={s.text_coin}>{t('headerMain.coins')}</span>
                </div>
                <div className={s.rate_wrapper}>
                  <img src="/img/star.png" alt="" />
                  &nbsp;
                  {rating}
                </div>
              </Stack>

              <Row className={s.ach_box_wrapper} md={4}>
                {/* заменит на будущий эндпоинт */}
                {achievements.map(({ index, image_filename, title }) => (
                  <Col key={title} className={s.ach_box} xs={4} lg={1} md={3}>
                    <OverlayTrigger
                      key={title}
                      placement="top"
                      overlay={<Tooltip id={`tooltip-${index}`}>{title}</Tooltip>}
                    >
                      <img src={`${image_filename}`} alt={title} />
                    </OverlayTrigger>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          {/* 
          <h2 className={s.title}>{t('modalUserInfo.userAwards')}</h2>
          <Col className={s.col} xs="11" lg="10" md="10">
            <div className={s.text_info}>
              <Row style={{ justifyContent: 'space-between' }}>
                {nameBtn.map((name) => (
                  <Col key={name} xs={4} lg={1} md={3} mb={1}>
                    <Button variant="outlined" fullWidth>
                      {name}
                    </Button>
                  </Col>
                ))}
              </Row>
              <p className={s.title_name}>
                вывести список ачивок в виде кнопок, при нажатии на которую происходит награждение пользователя
              </p>
            </div>
          </Col> */}
        </Row>
      </Modal.Body>
    </>
  );
};
