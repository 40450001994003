import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchWaitingEvents } from '../../../redux/reducers/adminPanelReducers/eventReducer/operations';
import { EventEditingForm } from '../forms/EventEditingForm/EventEditingForm';
import PaginationPanel from '../../Pagination/Pagination';
import { ModalW } from '../Modal/ModalW';
import Loader from '../../Loader/Loader';
import Table from '../Table/Table';
import s from '../../../pages/AdminPage/AdminPage.module.css';
import style from '../Table/Table.module.css';
import { COLS_WAITING_LIST } from '../eventMocs/eventMocs';

export const EventsWaitingList = () => {
  const dispatch = useDispatch();
  const waitingEvents = useSelector(({ events }) => events.waitingEvents);
  const pagination = useSelector((state) => state.events.waitingEventsEventsPagination);
  const status = useSelector(({ events }) => events.statusUpdateEvent);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [showEventForm, setShowEventForm] = useState(false);
  const { t } = useTranslation();

  const handlePageSelect = (_, value) => {
    dispatch(fetchWaitingEvents({ ...pagination, page: value }));
  };

  const handleClose = () => {
    // закрываем модалку с формой исправления ивента
    setShowEventForm(false);
  };

  const handleOpenModal = (eventId) => {
    // открывает модалку с формой исправления ивента
    setSelectedEventId(eventId);
    setShowEventForm(true);
  };

  const columns = useMemo(() => COLS_WAITING_LIST, []);

  useEffect(() => {
    status === 'response'
      ? dispatch(fetchWaitingEvents({ ...pagination, per_page: 10 }))
      : dispatch(fetchWaitingEvents({ ...pagination, per_page: 10 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status]);

  if (!waitingEvents) {
    return <Loader />;
  }

  return (
    <div>
      <h2 className={s.tableTitle}>{t('eventsWaitingList.waitingEvents')}</h2>
      <div className={style.tableContainer}>
        <Table data={waitingEvents} columns={columns} handleOpenModal={handleOpenModal} />
        <div className={s.pagination_box} xs="6" lg="6" md="6">
          <PaginationPanel fetchData={handlePageSelect} pagination={pagination} />
        </div>
      </div>

      {/* модалка с формой изменения ивента */}
      <ModalW show={showEventForm} handleClose={handleClose}>
        <EventEditingForm eventId={selectedEventId} handleClose={handleClose} />
      </ModalW>
    </div>
  );
};
