import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { addNews, clearNewsStatus } from '../../../../redux/reducers/adminPanelReducers/newsReducer/operation.js';
import s from '../CreateEventForm/CreateEventForm.module.css';
import style from './NewsForm.module.css';
import TextEditor from '../TextEditor/TextEditor.jsx';

export const NewsForm = () => {
  const dispatch = useDispatch();
  const status = useSelector(({ news }) => news.status); //статус после добавления новости
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [editorContent, setEditorContent] = useState('');

  /* ---------------- обработка изображения для вывода на экран --------------- */
  const handleReadeFile = (value) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setSelectedImage(event.target.result);
    };
    reader.readAsDataURL(value);
  };

  const onSubmitForm = (values, { resetForm }) => {
    values.description = editorContent;
    dispatch(addNews(values));

    setSelectedImage(null);
    resetForm();
  };

  /* ----------------- выводит сообщение об успешной операции ----------------- */
  useEffect(() => {
    status === 'response' && toast('Новина успішно збережена', { type: 'success' });
    status === 'rejected' && toast('Щось пішло не так', { type: 'error' });
  }, [status]);

  /* ------------------------ вызываем функцию сброса статуса ------------------------ */
  useEffect(() => {
    return () => {
      dispatch(clearNewsStatus());
    };
  }, [dispatch]);

  const initialValues = {
    title: 'ITISDev Запустились',
    description: '',
    img_file: null,
  };

  return (
    <div className={s.formWrapper}>
      <h2 className={s.formTitle}>{t('newsForm.titleForm')}</h2>
      <Formik onSubmit={onSubmitForm} initialValues={initialValues}>
        {({ handleSubmit, handleChange, setFieldValue, values }) => {
          /* ------ записывает картинку и передаёт в функцию для вывода на экран ------ */
          const handleAcheievement = (image) => {
            setFieldValue('img_file', image);

            handleReadeFile(image);
          };

          return (
            <Form onSubmit={handleSubmit}>
              <Row className={s.row_description} style={{ borderBottom: 'solid 1px grey', marginBottom: '10px' }}>
                {/* ---------------------- Инпут на ввод названия новости --------------------- */}
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className={s.formLabel}>{t('newsForm.titleNews')}</Form.Label>
                    <Form.Control
                      className={s.inputStyle}
                      required
                      type="text"
                      placeholder="ITIS Dev news"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>

                {/* ----------------------------- Загрузка ачивки ----------------------------- */}
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                    <Form.Label className={s.formLabel}>{t('newsForm.imgNews')}</Form.Label>
                    <Form.Control
                      className={s.inputStyle}
                      type="file"
                      name="img_file"
                      onChange={(e) => {
                        handleAcheievement(e.target.files[0]);
                      }}
                    />
                  </Form.Group>
                </Col>

                {/* ----------------------------- Описание ачивки ----------------------------- */}
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                  <Form.Label className={s.formLabel}>{t('newsForm.descriptionNews')}</Form.Label>
                  {/* <Form.Control
                    className={s.inputStyle}
                    required
                    as="textarea"
                    name="description"
                    placeholder="Техт..."
                    rows={3}
                    value={values.description}
                    onChange={handleChange}
                  /> */}
                  <TextEditor setEditorContent={setEditorContent} />
                </Form.Group>

                {/* -------------------------- отображение картинки -------------------------- */}
                <div className={style.achievWrapper}>
                  <img className={style.achievStyle} src={selectedImage} alt="" />
                </div>
              </Row>

              <Button style={{ fontSize: '1.6rem' }} type="submit">
                {t('newsForm.btnNews')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
