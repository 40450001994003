import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';
import Loader from '../Loader/Loader';
import storage from '../../services/localStorageService';
import { getUserAction } from '../../redux/action/getUserAction';
import { useSelector, useDispatch } from 'react-redux';
import HeaderMain from '../HeaderMain/HeaderMain';
// import ChatMessage from '../ChatMessage/ChatMessage';

const RequireAuth = ({ children }) => {
  // Получение информации о текущем пути
  const location = useLocation();
  const payload = useSelector((state) => state.info.payload);
  const isLoading = useSelector((state) => state.pageLoader.isLoading);
  const dispatch = useDispatch();

  // Если пользователь не авторизован, перенаправляем на страницу входа (Login)
  if (!storage.hasToken()) {
    return <Navigate to="/sign_in" state={{ from: location }} />;
  }

  if (!Object.keys(payload).length) {
    getUserAction(dispatch);
  }

  return (
    <>
      {isLoading && <Loader />}
      <HeaderMain />
      {children}
      {/* <ChatMessage/> */}
    </>
  );
};

export { RequireAuth };
