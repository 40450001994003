const GET_INFO_DEV_TEAM = 'GET_INFO_DEV_TEAM';


// State
const devTeamState = {
   payload: {
    
   }
};

// reducer принимает state и action
export const devTeamReducer = (state = devTeamState, action) => {
   switch (action.type) {
      case GET_INFO_DEV_TEAM:
         // Вывести в консоль action и payload, чтобы отслеживать действия
      
         return { ...state, payload: { ...state.payload, ...action.payload } };
      default:
         return state
   }
};

// Функции с экшенами которые будем передавать в диспатч - action creator
export const infoDevTeamActionCreator = (payload) => ({ type: GET_INFO_DEV_TEAM, payload});
