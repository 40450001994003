import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalW } from '../Modal/ModalW';
import { ModalUserInfo } from '../Modal/ModalUserInfo/ModalUserInfo';
import Loader from '../../Loader/Loader';
import { fetchUsers } from '../../../redux/reducers/adminPanelReducers/usersReducer/operations';
import s from '../../../pages/AdminPage/AdminPage.module.css';
import style from '../Table/Table.module.css';
import { useTranslation } from 'react-i18next';
import Table from '../Table/Table';
// import CircleIcon from '@mui/icons-material/Circle';
import PaginationPanel from '../../Pagination/Pagination';

export const UsersList = () => {
  const dispatch = useDispatch();
  const users = useSelector(({ dataUsers }) => dataUsers.users); //список юзеров
  const pagination = useSelector(({ dataUsers }) => dataUsers.usersPagination);
  const [userState, setUserState] = useState(null); //все данные юзера
  const [showUserInfo, setShowUserInfo] = useState(false); //открытие модалки
  const { t } = useTranslation();

  const handlePageSelect = (_, value) => {
    dispatch(fetchUsers({ ...pagination, page: value }));
  };

  const handleClose = () => {
    setShowUserInfo(false);
  };

  const handleOpenModal = (data) => {
  
    setUserState(data);
    setShowUserInfo(true);
  };

  //название колонок в таблице
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Ім"я',
        size: 200,
      },
      {
        accessorKey: 'created_at',
        header: 'Дата реєстрації',
        size: 200,
      },
      {
        accessorKey: 'specialization',
        header: 'Спеціалізація',
        size: 200,
      },
      {
        accessorKey: 'email',
        header: 'Пошта',
        size: 200,
      },
      {
        accessorKey: 'email_verified_at',
        header: 'Верифікований',
        size: 200,
        Cell: ({ cell }) => (cell.getValue() ? 'Так' : 'Ні'), //выставляем значение по булевому значению из базы
      },
      // {
      //   accessorKey: 'is_admin',
      //   header: 'У мережі',
      //   size: 140,
      //   Cell: ({ cell }) =>
      //     cell.getValue() ? (
      //       <CircleIcon color="success" title="Користувачь онлайн" />
      //     ) : (
      //       <CircleIcon color="error" title="Користувачь офлайн" />
      //     ),
      // },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchUsers({ ...pagination, per_page: 10 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!users && !pagination) {
    return <Loader />;
  }

  return (
    <>
      <h2 className={s.tableTitle}>{t('usersList.listOfUsers')}</h2>
      <div className={style.tableContainer}>
        <Table data={users} columns={columns} handleOpenModal={handleOpenModal} />
        <div className={s.pagination_box} xs="6" lg="6" md="6">
          <PaginationPanel fetchData={handlePageSelect} pagination={pagination} />
        </div>
      </div>
      {/* модалка с инфой */}
      <ModalW show={showUserInfo} handleClose={handleClose}>
        <ModalUserInfo user={userState} handleClose={handleClose} />
      </ModalW>
    </>
  );
};
