import requestService from '../../services/requestService';
// import { infoParticipantTeamActionCreator } from '../reducers/ParticipantTeamReducer';




export const allReadNotification = (dispatch) => {
    return requestService.update(`user/notification/mark-all-read`)
        .then( (response) => {
            return response.data;
          }
        
        );
};
