/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';

const GlobalStyles = () => (
  <Global
    styles={css`
      /* заднийфон */
      /* .MuiPaper-rootMuiPaper-elevationMuiPaper-roundedMuiPaper-elevation2 {
        background: var(--bg-input-color) !important;
        border: 2px solid white !important;
        border-radius: 10px !important;
        cursor: pointer !important;
      } */

      .MuiTableContainer-root::-webkit-scrollbar {
        height: 10px !important;
      }

      .MuiTableContainer-root::-webkit-scrollbar-track,
      .tableContainer::-webkit-scrollbar-track {
        border-radius: 10px !important;
        background: white !important;
      }

      .MuiTableContainer-root::-webkit-scrollbar-thumb,
      .tableContainer::-webkit-scrollbar-thumb {
        background: grey !important;
        border-radius: 10px !important;
        cursor: pointer !important;
      }

      /* панель инструментов */
      .MuiPaper-root .css-1tbggly,
      .MuiPaper-root .css-10gei56,
      .MuiPaper-root .css-nji4p8,
      .MuiPaper-root .css-8xn7hj {
        height: 45px !important;
        background-color: #71717a4d !important;
      }
      /* background: transparent !important; */

      /* +++++++++++++++++++++++++++ */
      /* MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation2 css-1atu56z-MuiPaper-root */
      /* .MuiPaper-root .MuiBox-root {
        height: 45px !important;
        background: #71717a4d !important;
      } */
      /* +++++++++++++++++++++++++++ */

      /* иконки в верхней панели */
      .MuiBox-root svg {
        fill: var(--white-color) !important;
        cursor: pointer !important;
      }

      .MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
        pointer-events: all !important;
      }

      /* input search */
      .MuiInputBase-root-MuiOutlinedInput-root {
        color: var(--white-color) !important;
      }

      /* input search column*/
      .MuiInputBase-root-MuiInput-root {
        color: var(--white-color) !important;
      }

      .MuiInputBase-root-MuiInput-root:before {
        border-bottom: 1px solid rgba(255, 255, 255, 0.42) !important;
      }

      /* контейнер пагинации */
      .css-8k4lth {
        padding-top: 4px !important;
        padding-bottom: 8px !important;
      }

      /* последняя ячейка в стоке */
      /* .css-14qx8np-MuiTableCell-root,
      .css-1ba1zdk-MuiTableCell-root,
      .css-1fmdo04-MuiTableCell-root {
        justify-content: flex-end;
      } */

      /* текст */
      /* .css-1f2oslk-MuiTypography-root, */
      /* .css-1sqonqc-MuiTypography-root, */
      .MuiTypography-root,
      .MuiFormLabel-root-MuiInputLabel-root,
      .MuiInputBase-root-MuiInput-root-MuiSelect-root,
      .MuiSelect-select-MuiInputBase-input-MuiInput-input {
        font-family: var(--primary-ff) !important;
        color: var(--white-color) !important;
        font-size: 1.4rem !important;
      }

      .MuiInputBase-root-MuiInput-root-MuiSelect-root option {
        padding: 0 10px !important;
        color: #000 !important;
      }

      /* стиль числа количества выводимых строк */
      .MuiSelect-select-MuiInputBase-input-MuiInput-input {
        box-sizing: inherit !important;
      }

      /* кнопка пагинации */
      /* .css-1atu56z-MuiPaper-root) {
  cursor: pointer !important;
} */

      /* иконки в нижней панели */
      /* .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root.Mui-disabled svg {
        fill: var(--white-color) !important;
      } */

      /* заднийфон
.css-1atu56z-MuiPaper-root) {
  background: var(--bg-input-color) !important;
  border: 2px solid white !important;
  border-radius: 10px !important;
  cursor: pointer !important;
} */

      /* шапка */
      .MuiTableRow-root {
        background: #0003 !important;
        box-shadow: 4px 0 8px rgba(255, 255, 255, 0.1) !important;
      }

      /* цвет нижнего бордера строки в таблице */
      th,
      td {
        border-bottom: 1px solid var(--border-input-color) !important;
      }

      /* div с text */
      /* .css-lapokc,
      .css-1nz3nwl,
      .css-bbxzxe  */
      .MuiTableCell-root {
        font-family: var(--primary-ff) !important;
        font-weight: 400 !important;
        color: var(--white-color) !important;
        font-size: 1.4rem !important;
      }

      /* div с стрелкой */
      .css-1c32n2y-MuiBadge-root {
        width: 25px;
        fill: aliceblue;
      }

      .css-1c32n2y-MuiBadge-root svg {
        fill: var(--white-color) !important;
      }

      .css-1p8dotb-MuiButtonBase-root-MuiIconButton-root svg {
        fill: var(--white-color) !important;
      }

      /* тело таблицы */
      /* строки */
      .MuiTableBody-root .MuiTableRow-root td {
        background: #212529 !important;
        /* background: #71717a4d !important; */

        font-family: var(--primary-ff) !important;
        font-weight: 400 !important;
        color: var(--white-color) !important;
        font-size: 1.2rem !important;
      }

      /* пагинация */
      .MuiPagination-ul {
        justify-content: center !important;
        background-color: transparent !important;
      }

      .MuiPagination-ul [type='button'] {
        color: var(--white-color) !important;
        border: 1px solid var(--primary-color) !important;
        transition: all 0.3s !important;
      }

      .MuiPagination-ul [type='button']:not(:disabled).Mui-selected {
        color: var(--secondary-color) !important;
        border: 1px solid var(--secondary-color) !important;
      }

      .MuiPagination-ul [type='button']:not(:disabled):hover {
        color: var(--secondary-color) !important;
        border: 1px solid var(--secondary-color) !important;
        transition: all 0.3s !important;
      }

      /* .css-12i7wg6-MuiPaper-root-MuiDrawer-paper { */
      .MuiDrawer-paper {
        position: relative !important;
        height: 85rem !important;
        color: var(--primary-color) !important;
        background: var(--bg-input-color) !important;
        border-right: 2px solid white !important;
        z-index: 998 !important;
      }
    `}
  />
);

export default GlobalStyles;
