import { createReducer } from '@reduxjs/toolkit';
import { fetchRegisteredUsers, fetchStatistiks } from './operations';

export const dataStatistiks = createReducer(
  {},
  {
    [fetchStatistiks.fulfilled]: (_, { payload }) => payload,
  }
);

export const dataRegisteredUsers = createReducer(
  {},
  {
    [fetchRegisteredUsers.fulfilled]: (_, { payload }) => payload,
  }
);
