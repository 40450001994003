import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from '../../../../services/requestService';

export const fetchStatistiks = createAsyncThunk('statistiks/fetchStatistiks', async (_, thunkAPI) => {
  try {
    const response = await requestService.get('admin-panel/base-statistic');
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const fetchRegisteredUsers = createAsyncThunk('statistiks/fetchRegisteredUsers', async (_, thunkAPI) => {
  try {
    const response = await requestService.get('admin-panel/registered-users');
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});
