import { createSlice } from '@reduxjs/toolkit';
import { applyCommand, clearStatus, switchTechnicalBreak } from './operation';

// Получение сохраненного значения switch из localStorage
const savedSwitch = localStorage.getItem('switch');

//Функция создание ивентов
const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    status: null,
    switch: savedSwitch ? JSON.parse(savedSwitch) : false, // Используем сохраненное значение или false по умолчанию
  },

  extraReducers: {
    // состояние после нжатия кнопки
    [applyCommand.pending](state) {
      state.status = 'loading';
    },
    [applyCommand.fulfilled](state) {
      state.status = 'response';
    },
    [applyCommand.rejected](state) {
      state.status = 'rejected';
    },
    // обнуление статуся
    [clearStatus.fulfilled](state) {
      state.status = null;
    },

    // булевое состояние свитчера
    [switchTechnicalBreak.pending](state, { meta }) {
      state.switch = meta.arg;
      // Сохраняем значение switch в localStorage
      localStorage.setItem('switch', JSON.stringify(meta.arg));
    },
  },
});

export const settingsReducer = settingsSlice.reducer;
