export const DIFFICULTY_LEVEL = ['junior', 'middle', 'senior'];

export const SPECIALIZATION_NAME = ['full_stack', 'backend', 'frontend'];

export const COLS_ACTIVE_LIST = [
  {
    accessorKey: 'title',
    header: 'Назва івенту',
    size: 200,
  },
  {
    accessorKey: 'difficulty_level',
    header: 'Рівень складності',
    size: 200,
  },
  {
    accessorKey: 'team_max_users_count',
    header: 'Кількість учасників',
    size: 200,
  },
  {
    accessorKey: 'started_at',
    header: 'Початок',
    size: 200,
  },
  {
    accessorKey: 'finished_at',
    header: 'Завершення',
    size: 200,
  },
];

export const COLS_ARCHIVE_LIST = [
  {
    accessorKey: 'title',
    header: 'Назва івенту',
    size: 200,
  },
  {
    accessorKey: 'specialization',
    header: 'Для кого',
    size: 200,
  },
  {
    accessorKey: 'difficulty_level',
    header: 'Рівень складності',
    size: 200,
  },
  {
    accessorKey: 'team_max_users_count',
    header: 'Кількість учасників',
    size: 200,
  },
  {
    accessorKey: 'started_at',
    header: 'Початок',
    size: 200,
  },
  {
    accessorKey: 'finished_at',
    header: 'Завершений',
    size: 200,
  },
];

export const COLS_OPEN_LIST = [
  {
    accessorKey: 'title',
    header: 'Назва івенту',
    size: 200,
  },
  {
    accessorKey: 'difficulty_level',
    header: 'Рівень складності',
    size: 200,
  },
  {
    accessorKey: 'team_max_users_count',
    header: 'Кількість учасників',
    size: 200,
  },
  {
    accessorKey: 'started_at',
    header: 'Початок',
    size: 200,
  },
  {
    accessorKey: 'finished_at',
    header: 'Завершення',
    size: 200,
  },
];
export const COLS_WAITING_LIST = [
  {
    accessorKey: 'title',
    header: 'Назва івенту',
    size: 200,
  },
  {
    accessorKey: 'difficulty_level',
    header: 'Рівень складності',
    size: 200,
  },
  {
    accessorKey: 'team_max_users_count',
    header: 'Кількість учасників',
    size: 200,
  },
  {
    accessorKey: 'started_at',
    header: 'Початок',
    size: 200,
  },
  {
    accessorKey: 'finished_at',
    header: 'Завершення',
    size: 200,
  },
];
