import React from 'react';
import s from './Error403Page.module.css';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Error403Page = () => {
  const { t } = useTranslation();
  return (
    <div className={s.wrapperContent}>
      <div className={s.contentBox}>
        <h3 className={s.title}>{t('error403Page.notAvailable')}</h3>
        <p className={s.text}>
          {t('error403Page.text1')} <br /> {t('error403Page.text2')}
        </p>

        <Button className={s.btn} type="button">
          <Link to="/home">{t('error403Page.returnHome')}</Link>
        </Button>
      </div>
    </div>
  );
};

export default Error403Page;
