import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from '../../../../services/requestService';
const translations = require('../../../../locales/ua/translationAdmin');

// ивенты в ожидании
export const fetchWaitingEvents = createAsyncThunk('events/fetchWaitingEvents', async (pagination, thunkAPI) => {
  try {
    const response = await requestService.get('admin-panel/events/waiting', { ...pagination });

    return response.data;
  } catch (error) {
    console.error('Ошибка при запросе:', error);

    console.log('Error response:', error.response);
    console.log('Error request:', error.request);
    console.log('Error config:', error.config);
  }
});

// открытые ивенты
export const fetchOpenEvents = createAsyncThunk('events/fetchOpenEvents', async (pagination, thunkAPI) => {
  try {
    const response = await requestService.get('admin-panel/events/open', { ...pagination });

    return response.data;
  } catch (error) {
    console.error('Ошибка при запросе:', error);

    console.log('Error response:', error.response);
    console.log('Error request:', error.request);
    console.log('Error config:', error.config);
  }
});

//активные ивенты
export const fetchActiveEvents = createAsyncThunk('events/fetchActiveEvents', async (pagination, thunkAPI) => {
  try {
    const response = await requestService.get('admin-panel/events/active', { ...pagination });

    return response.data;
  } catch (error) {
    console.error('Ошибка при запросе:', error);

    console.log('Error response:', error.response);
    console.log('Error request:', error.request);
    console.log('Error config:', error.config);
  }
});

//архивные ивенты
export const fetchArchiveEvents = createAsyncThunk('events/fetchArchiveEvents', async (pagination, thunkAPI) => {
  try {
    const response = await requestService.get('admin-panel/events/archive', { ...pagination });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

// Добавление ивента
export const createEvent = createAsyncThunk('events/addEvent', async (eventData) => {
  try {
    const formData = new FormData();
    Object.keys(eventData).forEach((key) =>
      formData.append(key, key === 'agenda' ? JSON.stringify(eventData[key]) : eventData[key])
    );
    formData.append('agenda_translations', JSON.stringify(translations.agenda));

    const response = await requestService.create('admin-panel/event', formData);
    window.open(response.data.pdf_url, '_blank');

    return response.data;
  } catch (error) {
    console.error('Ошибка при создании ивента:', error);

    console.log('Error response:', error.response);
    console.log('Error request:', error.request);
    console.log('Error config:', error.config);
    throw error;
  }
});

// обновление ивента
export const updateEventById = createAsyncThunk('events/updateEvent', async (eventData) => {
  try {
    // создаём новый обьект длятого что б перевести ключь agenda в JSON формат
    const formData = new FormData();
    Object.keys(eventData).forEach((key) =>
      formData.append(key, key === 'agenda' ? JSON.stringify(eventData[key]) : eventData[key])
    );
    formData.append('agenda_translations', JSON.stringify(translations.agenda));

    const response = await requestService.create('admin-panel/event?_method=PUT', formData);
    window.open(response.data.pdf_url, '_blank');

    return response.data;
  } catch (error) {
    console.error('Ошибка при обновлении ивента:', error);
    console.log('Error response:', error.response);
    console.log('Error request:', error.request);
    console.log('Error config:', error.config);
  }
});
