import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import {
  addAchievement,
  clearAchievementStatus,
  fetchAchievementCodes,
} from '../../../../redux/reducers/adminPanelReducers/achievementReducer/operation';
import s from '../CreateEventForm/CreateEventForm.module.css';
import style from './AddImgAchievementForm.module.css';

export const AddImgAchievementForm = () => {
  const dispatch = useDispatch();
  const achievementCodes = useSelector(({ achievement }) => Object.keys(achievement.achievementCodes)); //масив кодов к ачивкам
  const status = useSelector(({ achievement }) => achievement.status);
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);

  /* ---------------- обработка изображения для вывода на экран --------------- */
  const handleReadeFile = (value) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setSelectedImage(event.target.result);
    };
    reader.readAsDataURL(value);
  };

  const onSubmitForm = (values) => {
    dispatch(addAchievement(values));

    setSelectedImage(null);
  };

  /* ----------------- выводит сообщение об успешной операции ----------------- */
  useEffect(() => {
    status === 'response' && toast('Ачівка успішно збережена', { type: 'success' });
  }, [status]);

  /* ------------------------ передаёт статус в редакс ------------------------ */
  useEffect(() => {
    return () => {
      dispatch(clearAchievementStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* ------------------------ Запрос на сервер за списком кодов ачивок ------------------------ */
  useEffect(() => {
    dispatch(fetchAchievementCodes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    title: '',
    description: 'Опис ачівки',
    achievement_img: null,
    image_filename: '',
  };

  return (
    <div className={s.formWrapper}>
      <h2 className={s.formTitle}>{t('achForm.titleForm')}</h2>
      <Formik onSubmit={onSubmitForm} initialValues={initialValues}>
        {({ handleSubmit, handleChange, setFieldValue, values }) => {
          /* ------ записывает картинку и передаёт в функцию для вывода на экран ------ */
          const handleAcheievement = (image) => {
            setFieldValue('achievement_img', image);

            handleReadeFile(image);
          };

          return (
            <Form onSubmit={handleSubmit}>
              <Row className={s.row_description} style={{ borderBottom: 'solid 1px grey', marginBottom: '10px' }}>
                {/* ---------------------- Инпут на ввод названия ачивки --------------------- */}
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className={s.formLabel}>{t(`achievement.name`)}</Form.Label>
                    <Form.Control
                      className={s.inputStyle}
                      required
                      type="text"
                      placeholder="Приключение рубика"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>

                {/* ----------------------------- Выбор типа ачивки ----------------------------- */}
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className={s.formLabel}>{t(`achievement.type`)}</Form.Label>
                    <Form.Select
                      className={s.inputStyle}
                      name="image_filename"
                      value={values.image_filename}
                      onChange={handleChange}
                    >
                      <option>{t(`achievement.selectType`)}</option>
                      {achievementCodes.map((name) => {
                        return (
                          <option style={{ fontSize: '1.6rem' }} key={name} value={name}>
                            {t(`achievement.${name}`)}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>

                {/* ----------------------------- Загрузка ачивки ----------------------------- */}
                <Col>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                    <Form.Label className={s.formLabel}>{t(`achievement.addAchievement`)}</Form.Label>
                    <Form.Control
                      className={s.inputStyle}
                      type="file"
                      name="achievement_img"
                      onChange={(e) => {
                        handleAcheievement(e.target.files[0]);
                      }}
                    />
                  </Form.Group>
                </Col>

                {/* ----------------------------- Описание ачивки ----------------------------- */}
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                  <Form.Label className={s.formLabel}>{t(`achievement.descriptionShort`)}</Form.Label>
                  <Form.Control
                    className={s.inputStyle}
                    required
                    as="textarea"
                    name="description"
                    placeholder="Техт..."
                    rows={3}
                    value={values.description}
                    onChange={handleChange}
                  />
                </Form.Group>

                {/* -------------------------- отображение картинки -------------------------- */}
                <div className={style.achievWrapper}>
                  <img className={style.achievStyle} src={selectedImage} alt="" />
                </div>
              </Row>

              <Button style={{ fontSize: '1.6rem' }} type="submit">
                {t(`achievement.btnAdd`)}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
