import requestService from '../../services/requestService';
// import { infoParticipantTeamActionCreator } from '../reducers/ParticipantTeamReducer';




export const oneReadNotification = (dispatch, idNotification) => {
    return requestService.update(`user/notification/mark-one-read`, {id:idNotification})
        .then( (response) => {
            return response.data;
          }
        
        );
};
