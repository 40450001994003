const GET_INFO_USER = 'GET_INFO_USER';


// State
const userState = {
   payload: {
    
   }
};

// reducer принимает state и action
export const infoUserReducer = (state = userState, action) => {
   switch (action.type) {
      case GET_INFO_USER:
         return { ...state, payload: { ...state.payload, ...action.payload } };
      default:
         return state
   }
};

// Функции с экшенами которые будем передавать в диспатч - action creator
export const infoUserActionCreator = (payload) => ({ type: GET_INFO_USER, payload});
