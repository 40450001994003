import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';
import Loader from '../Loader/Loader';
import storage from '../../services/localStorageService';
import { getAdminProfileAction } from '../../redux/action/getUserAction';
import { useSelector, useDispatch } from 'react-redux';
import HeaderMain from '../HeaderMain/HeaderMain';
import Error403Page from '../../pages/Error403Page/Error403Page';

const RequireAuthAdmin = ({ children }) => {
  // Получение информации о текущем пути
  const location = useLocation();
  const userProfile = useSelector((state) => state.info.payload);
  const isLoading = useSelector((state) => state.pageLoader.isLoading);
  const dispatch = useDispatch();

  // Если пользователь не авторизован, перенаправляем на страницу входа (Login)
  if (!storage.hasToken()) {
    return <Navigate to="/sign_in" state={{ from: location }} />;
  }

  if (!Object.keys(userProfile).length) {
    getAdminProfileAction(dispatch);
  }

  return (
    <>
      {userProfile?.is_admin ? (
        <>
          {isLoading && <Loader />}
          <HeaderMain />
          {children}
        </>
      ) : (
        <Error403Page />
      )}
    </>
  );
};

export { RequireAuthAdmin };
