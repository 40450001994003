import { infoUserActionCreator } from '../reducers/infoUserReducer'
import requestService from '../../services/requestService'

// Экшен для отправки данных на сервер ДАННЫЕ ЮЗЕРА

export const getUserAction = (dispatch) => {
  return requestService
      .get('user/profile')
      .then((response) => {
        dispatch(infoUserActionCreator(response.data));
      });
};

export const getAdminProfileAction = (dispatch) => {
  return requestService
    .get('admin-panel/users/me')
    .then((response) => {
      dispatch(infoUserActionCreator(response.data));
    });
};
