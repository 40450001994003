import { createSlice } from '@reduxjs/toolkit';
import { addAchievement, clearAchievementStatus, fetchAchievementCodes } from './operation';

//Функция создание ивентов
const achievementSlice = createSlice({
  name: 'achievement',
  initialState: {
    status: null,
    achievementCodes: {},
  },

  extraReducers: {
    [addAchievement.pending](state) {
      state.status = 'loading';
    },
    [addAchievement.fulfilled](state) {
      state.status = 'response';
    },
    [addAchievement.rejected](state) {
      state.status = 'rejected';
    },
    [clearAchievementStatus.fulfilled](state) {

      state.status = null;
    },
    [fetchAchievementCodes.fulfilled](state, { payload }) {
      state.achievementCodes = payload;
    },
  },
});

export const achievementReducer = achievementSlice.reducer;
