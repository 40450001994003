import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TextEditor = ({ initialValue = '', setEditorContent, CrEvent }) => {
  const editorRef = useRef(null);
  const height = `${CrEvent ? '200' : '500'}`;

  const value = `${initialValue.content || '<p>Пиши шо ти там хотів! ))</p>'}`;

  return (
    <>
      <Editor
        apiKey="0kng69qj1zsuxkdyz3y6bl24czvv4wx0kbpe4q230d4qqw8y"
        onInit={(_evt, editor) => (editorRef.current = editor)}
        initialValue={value}
        init={{
          height: height,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount',
            // 'lineheight',
            'emoticons',
          ],
          toolbar:
            'undo redo | formatselect | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'emoticons | link image media' +
            'insertdatetime | searchreplace | preview fullscreen | ' +
            'removeformat | help',
          // 'lineheight | removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
        onEditorChange={(content) => setEditorContent(content)}
      />
    </>
  );
};

export default TextEditor;
