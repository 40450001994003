import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useLocation, useNavigate } from 'react-router-dom';
import './Table.module.css';

const Table = ({ data, columns, handleOpenModal }) => {
  const navigate = useNavigate();
  const currentPage = useLocation();

  const handleOpenArhivePage = (eventUuid) => {
    // открывает страницу подробностей об архивном ивенте
    navigate(`/event/details/${eventUuid}`);
  };

  const table = useMaterialReactTable({
    columns,
    data: data || [],
    enablePagination: false,
    enableRowNumbers: true,
    rowNumberDisplayMode: 'custom', // Set the row number display mode to custom
    layoutMode: 'grid-no-grow', // При необходимости корректируем режим макета

    muiTableBodyRowProps: ({ row, index }) => ({
      onClick: () => {
        // переадресация на инфу архивного ивента
        if (currentPage.pathname === '/admin/events-archive-list') {
          handleOpenArhivePage(row.original.uuid);

          // открытие модалки с юзером
        } else if (currentPage.pathname === '/admin') {
          handleOpenModal(row.original);

          // открывает модалку редактирования ивента только на странице events-waiting-list и если !is_visible
        } else if (currentPage.pathname === '/admin/events-waiting-list' && !row.original.is_visible) {
          handleOpenModal(row.original.id);
        }
      },
      // нумирация
      children: <div>{index + 1}</div>,
      // настройка стилей
      sx: {
        // background: 'transparent !important',
        cursor: 'pointer',
      },
    }),
  });

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

export default Table;
