import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchArchiveEvents } from '../../../redux/reducers/adminPanelReducers/eventReducer/operations';
import PaginationPanel from '../../Pagination/Pagination';
import Loader from '../../Loader/Loader';
import Table from '../Table/Table';
import s from '../../../pages/AdminPage/AdminPage.module.css';
import style from '../Table/Table.module.css';
import { COLS_ARCHIVE_LIST } from '../eventMocs/eventMocs';

export const EventsArchiveList = () => {
  const dispatch = useDispatch();
  const arhiveEvents = useSelector(({ events }) => events.arhiveEvents);
  const pagination = useSelector((state) => state.events.archiveEventsPagination);
  const status = useSelector((state) => state.error.payload.status);
  const { t } = useTranslation();

  const handlePageSelect = (_, value) => {
    dispatch(fetchArchiveEvents({ ...pagination, page: value }));
  };

  const columns = useMemo(() => COLS_ARCHIVE_LIST, []);

  useEffect(() => {
    dispatch(fetchArchiveEvents({ ...pagination, per_page: 10 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (status !== 200 && !arhiveEvents && !pagination) {
    return <Loader />;
  }

  return (
    <div>
      <h2 className={s.tableTitle}>{t('eventsArchiveList.archivedEvents')}</h2>
      <div className={style.tableContainer}>
        <Table data={arhiveEvents} columns={columns} />
        <div className={s.pagination_box} xs="6" lg="6" md="6">
          <PaginationPanel fetchData={handlePageSelect} pagination={pagination} />
        </div>
      </div>
    </div>
  );
};
