const ERROR_SERVER = 'ERROR_SERVER';
const ERROR_RESET = 'ERROR_RESET';
const SUCCESS = 'SUCCESS';


// State
const errorState = {
    payload: {
        status: undefined,
        message: ''
    },
};

// reducer принимает state и action
export const errorReducer = (state = errorState, action) => {
    switch (action.type) {
        case ERROR_SERVER:
            return {...state, payload: action.payload};
        case ERROR_RESET:
            return {
                ...state, payload: {
                    status: undefined,
                    message: ''
                }
            };
        case SUCCESS:
            return {
                ...state, payload: {
                    status: 200,
                    message: ''
                }
            };
        default:
            return state
    }
}

// Функции с экшенами которые будем передавать в диспатч - action creator
export const errorActionCreator = (payload) => ({type: ERROR_SERVER, payload});

export const errorActionReset = (payload) => ({type: ERROR_RESET, payload});

export const errorActionSuccess = (payload) => ({type: SUCCESS, payload});
