import {toast} from "react-toastify";
import {isArray} from "chart.js/helpers";
import storage from './localStorageService';

const ERROR_OPTIONS = {type: "error"};
const DEFAULT_ERROR_MESSAGE = 'Сталася помилка. Спробуйте пізніше';

export default class ResponseError {
    handle(error) {
        // console.log(error);
        if (error.response?.status === 401) {
            storage.removeToken();
            window.location.replace('/sign_in');
        } else if (error.response?.status === 403) {
            window.location.replace('/error-403');
        } else if (error.response?.status === 404) {
            window.location.replace('/*');
        } else if (error.response?.status === 503) {
            window.location.replace('/technical-break');
        } else {
            this._showMessage(error);
        }

        return Promise.reject(error);
    }

    _showMessage(error) {
        if (error.response?.data?.error?.message) {
            if(isArray(error.response.data.error.message)) {
                error.response.data.error.message.forEach(message => toast(message, ERROR_OPTIONS));
            } else {
                toast(error.response.data.error.message, ERROR_OPTIONS);
            }
        } else {
            toast(DEFAULT_ERROR_MESSAGE, ERROR_OPTIONS);
        }
    }
}