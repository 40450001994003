import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

// Styles
import s from './HeaderMain.module.css';
import ProfileMenu from '../ProfilMenu/ProfilMenu';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UserNotification from '../UserNotification/UserNotification'

function HeaderMain() {
  const { t } = useTranslation();
  const location = useLocation();

  const payload = useSelector((state) => state.info.payload);

  const admin = payload.is_admin ?? '';
  const rating = payload.rating ?? '';
  const coins = payload.coins ?? '';

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <Container className={s.container} fluid>
      <Row className={s.row}>
        <Col xs="7" lg="2" md="2">
          <Link to="/events/open">
            <img className={s.img_header} src="/img/LogoITIS2.png" alt="" />
          </Link>
        </Col>
        <Col className={s.menu_item_list} xs="11" lg="5" md="5" style={{ textAlign: 'center'  }}>
          <Link to="/home" className={`${s.menu_item} ${isActive('/home') ? s.active : ''}`}>
            {t('headerMain.main')}
          </Link>
          <Link to="/events/open" className={`${s.menu_item} ${isActive('/events/open') ? s.active : ''}`}>
            {t('headerMain.events')}
          </Link>
          <Link to="/news" className={`${s.menu_item} ${isActive('/news') ? s.active : ''}`}>
            Новини
          </Link>
          <Link to="/faq" className={`${s.menu_item} ${isActive('/faq') ? s.active : ''}`}>
            FAQ
          </Link>
          {admin ? (
            <Link to="/admin" className={`${s.menu_item} ${isActive('/admin') ? s.active : ''}`}>
              {t('headerMain.admin')}
            </Link>
          ) : (
            ''
          )}
        </Col>
        <Col className={s.zone_menu} xs="8" lg="3" md="2">
          <ProfileMenu />
          <UserNotification/>
          <div className={s.coin_wrapper}>
            <img src="/img/coin.png" alt="" />
            &nbsp;
            {coins} <span className={s.text_coin}>{t('headerMain.coins')}</span>
          </div>
          <div className={s.rate_wrapper}>
            <img src="/img/star.png" alt="" />
            &nbsp;
            {rating}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default HeaderMain;
