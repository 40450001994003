import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchOpenEvents } from '../../../redux/reducers/adminPanelReducers/eventReducer/operations';
import Loader from '../../Loader/Loader';
import Table from '../Table/Table';
import PaginationPanel from '../../Pagination/Pagination';
import s from '../../../pages/AdminPage/AdminPage.module.css';
import style from '../Table/Table.module.css';
import { COLS_OPEN_LIST } from '../eventMocs/eventMocs';

export const EventsOpenList = () => {
  const dispatch = useDispatch();
  const openEvents = useSelector(({ events }) => events.openEvents);
  const pagination = useSelector((state) => state.events.openEventsPagination);
  const isCreate = useSelector(({ events }) => events.isCreate);
  const status = useSelector((state) => state.error.payload.status);
  const { t } = useTranslation();

  const handlePageSelect = (_, value) => {
    dispatch(fetchOpenEvents({ ...pagination, page: value }));
  };

  const columns = useMemo(() => COLS_OPEN_LIST, []);

  useEffect(() => {
    dispatch(fetchOpenEvents({ ...pagination, per_page: 10 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (status !== 200 && !openEvents && isCreate) {
    return <Loader />;
  }

  return (
    <div>
      <h2 className={s.tableTitle}>{t('eventsOpenList.openEvents')}</h2>
      <div className={style.tableContainer}>
        <Table data={openEvents} columns={columns} />
        <div className={s.pagination_box} xs="6" lg="6" md="6">
          <PaginationPanel fetchData={handlePageSelect} pagination={pagination} />
        </div>
      </div>
    </div>
  );
};
