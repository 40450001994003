import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from '../../../../services/requestService';

// Добавление новости с картинкой
export const addNews = createAsyncThunk('news/addNews', async (newsData) => {
  try {
    // formData используется для обработки файла перед загрузкой на сервер
    const formData = new FormData();
    Object.keys(newsData).forEach((key) => formData.append(key, newsData[key]));

    const response = await requestService.create('admin-panel/announcement', formData);

    return response.data;
  } catch (error) {
    console.log('Помилка при додаванні новин:', error);

    throw error;
  }
});

export const clearNewsStatus = createAsyncThunk('news/clearStatus', async () => {});
